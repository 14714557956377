import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';
import ShowUserProfile from './components/UserProfile/ShowUserProfile';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import ProfileEdit from './components/UserProfile/ProfileEdit';
import ProfileSettings from './components/UserProfile/ProfileSettings';
import ChangePassword from './components/UserProfile/ChangePassword';
import Profile from './components/UserProfile/Profile';
import UserDash from './components/Maindash/UserDash';
import Header from './components/Header/Header';
import Dashboard from './components/Maindash/Dashboard';
import { GlobalStateProvider } from './components/constants/GlobalStateProvider';
import AllMachinesDetails from './components/Machines/AllMachinesDetails';
import IndexMachines from './components/Machines/IndexMachines';
import SpecificMachineTable from './components/Machines/SpecificMachineTable';
import AssignFuelCapacity from './components/Machines/AssignFuelCapacity';
import GenerateReport from './components/Machines/GenerateReport';
import IndexGenerateReport from './components/Machines/IndexGenerateReport';

import IndexLocation from './components/Location/IndexLocation';

import ContactEmail from './components/contact/ContactEmail';
import IndexContactemail from './components/contact/IndexContactemail';
import SetMachineLocation from './components/location1/SetMachineLocation';
import IndexMachineLocation from './components/location1/IndexMachineLocation';
import IndexSpecificMachineTable from './components/Machines/IndexSpecificMachineTable';
import IndexAnalytics from './components/Analytics/IndexAnalytics';
import GensetStatus from './components/Analytics/GensetStatus';
import NotificationsForm from './components/Notifications/NotificationsForm';
import IndexNotifications from './components/Notifications/IndexNotifications';
import AllNotifications from './components/Notifications/AllNotifications';
import IndexAllNotifications from './components/Notifications/IndexAllNotifications';


ReactDOM.render(
  <GlobalStateProvider>
  <React.StrictMode>
    
    <Router>
      <Routes>
        <Route path="/" element={<App />} />
        <Route path="/Dashboard" element={<Dashboard/>} />
        <Route path="/Profile" element={<Profile />} />
        <Route path="/Profile/Overview" element={<ShowUserProfile />} />
        <Route path="/Profile/Edit" element={<ProfileEdit />} />
        <Route path="/Profile/Setting" element={<ProfileSettings />} />
        <Route path="/Profile/ChangePassword" element={<ChangePassword />} />
        <Route path="/UserDash" element={<UserDash/>} />
        <Route path="/Header" element={<Header/>} />
        <Route path="/AllMachinesDetails" element={<AllMachinesDetails/>} />
        <Route path="/IndexMachines" element={<IndexMachines/>} />
        <Route path="/machine/:machineId" element={<SpecificMachineTable />} />
        <Route path="/AssignFuelCapacity" element={<AssignFuelCapacity/>} />
        <Route path="/GenerateReport" element={<GenerateReport/>} />
        <Route path="/IndexGenerateReport" element={<IndexGenerateReport/>} />

        <Route path="/IndexLocation" element={<IndexLocation/>}/>

        <Route path="/ContactEmail" element={<ContactEmail/>} />
        <Route path="/IndexContactemail" element={<IndexContactemail/>} />
        <Route path="/SetMachineLocation" element={<SetMachineLocation/>} />
        <Route path="/IndexMachineLocation" element={<IndexMachineLocation/>} />
        <Route path="/indexspecificmachine/:machineId" element={<IndexSpecificMachineTable/>} />
        <Route path="/IndexAnalytics" element={<IndexAnalytics/>} />
        <Route path="/gensetstatus/:machineId" element={<GensetStatus/>} />
        <Route path="/NotificationsForm" element={<NotificationsForm/>} />
        <Route path="/IndexNotifications" element={<IndexNotifications/>} />
        <Route path="/AllNotifications" element={<AllNotifications/>} />
        <Route path="/IndexAllNotifications" element={<IndexAllNotifications/>} />


      </Routes>
    </Router>
   
  </React.StrictMode> 
  </GlobalStateProvider>,
  document.getElementById('root')
);

reportWebVitals();