// import React, { useState, useEffect } from 'react';
// import axios from 'axios';
// import { useLocation } from 'react-router-dom';
// import { API_URL } from '../constants/Url';
// import { useGlobalState } from '../constants/GlobalStateProvider';

// const SetMachineLocation = () => {
//   const [machineIds, setMachineIds] = useState([]);
//   const [selectedMachineId, setSelectedMachineId] = useState('');
//   const location = useLocation();
//   const { getGlobal } = useGlobalState();
//   const globalState = getGlobal();
//   const [locationAttributes, setLocationAttributes] = useState({
//     machine_name: '',
//     location_name: '',
//     zone_name: '',
//     latitude: '',
//     longitude: '',
//     fuelCapacity: '', // Added fuel capacity field
//   });

//   useEffect(() => {
//     if (globalState) {
//       fetchMachineInfo(globalState);
//     }
//   }, [location, globalState]);

//   const fetchMachineInfo = async (globalState) => {
//     try {
//       const response = await axios.get(`${API_URL}/machines/user/${globalState}`);
//       const data = response.data;

//       if (data.success) {
//         setMachineIds(data.machine_ids);
//       }
//     } catch (error) {
//       console.error('Error fetching machine info:', error);
//     }
//   };

//   const handleInputChange = (event) => {
//     const { name, value } = event.target;
//     setLocationAttributes((prevAttributes) => ({
//       ...prevAttributes,
//       [name]: value,
//     }));
//   };

//   const handleSubmit = async (event) => {
//     event.preventDefault();

//     const dataToSubmit = {
//       user_id: globalState,
//       machine_id: selectedMachineId,
//       machine_name: locationAttributes.machine_name,
//       location_name: locationAttributes.location_name,
//       zone_name: locationAttributes.zone_name,
//       latitude: locationAttributes.latitude,
//       longitude: locationAttributes.longitude,
//     };

//     try {
//       const response = await axios.post(`${API_URL}/machines/set_location`, dataToSubmit);
//       if (response.data.success) {
//         alert('Machine location updated successfully');
//       } else {
//         alert('Failed to update machine location');
//       }
//     } catch (error) {
//       console.error('Error updating machine location:', error);
//     }
//   };

//   const containerStyle = {
//     padding: '20px',
//     backgroundColor: '#f8f9fa',
//     borderRadius: '10px',
//     boxShadow: '0 4px 8px rgba(0, 0, 0, 0.1)',
//     backgroundImage: 'url("assets/img/locationbg2.jpg")',
//     backgroundSize: 'cover',
//     backgroundPosition: 'center',
//     alignItems: 'center',
//   };

//   const cardStyle = {
//     backgroundColor: 'rgba(194, 223, 246, 0.4)',
//     borderRadius: '10px',
//     padding: '20px',
//     marginBottom: '20px',
//     marginLeft: '50px',
//     marginRight: '50px',
//     marginTop: '40px',
//   };
//   const headingStyle = {
//     textAlign: 'center',
//     marginBottom: '20px',
//     color: '#333',
//     marginTop: '20px',
//     fontWeight: 'bold',
    
//   };

//   const buttonStyle = {
//     display: 'inline-block',
//     padding: '10px 30px',
//     borderRadius: '50px',
//     transition: '0.5s',
//     marginTop: '10px',
//     color: '#fff',
//     backgroundColor: '#007bff',
//     textDecoration: 'none',
//     cursor: 'pointer',
//     textAlign: 'center',
//   };

//   return (
//     <div style={{ padding: '20px', backgroundColor: '#f8f9fa', borderRadius: '10px' }}>
//       <h3>Set Machine Location</h3>
//       <form onSubmit={handleSubmit}>
//         <div className="form-group">
//           <label htmlFor="machineId">Select Machine ID</label>
//           <select
//             id="machineId"
//             className="form-control"
//             value={selectedMachineId}
//             onChange={(e) => setSelectedMachineId(e.target.value)}
//           >
//             <option value="">Select Machine ID</option>
//             {machineIds.map((machineId, index) => (
//               <option key={index} value={machineId}>
//                 {machineId}
//               </option>
//             ))}
//           </select>
//         </div>
//         <div className="form-group">
//           <label htmlFor="machineName">Machine Name</label>
//           <input
//             type="text"
//             id="machineName"
//             name="machine_name"
//             className="form-control"
//             value={locationAttributes.machine_name}
//             onChange={handleInputChange}
//           />
//         </div>
//         <div className="form-group">
//           <label htmlFor="locationName">Location Name</label>
//           <input
//             type="text"
//             id="locationName"
//             name="location_name"
//             className="form-control"
//             value={locationAttributes.location_name}
//             onChange={handleInputChange}
//           />
//         </div>
//         <div className="form-group">
//           <label htmlFor="zoneName">Zone Name</label>
//           <input
//             type="text"
//             id="zoneName"
//             name="zone_name"
//             className="form-control"
//             value={locationAttributes.zone_name}
//             onChange={handleInputChange}
//           />
//         </div>
//         <div className="form-group">
//           <label htmlFor="latitude">Latitude</label>
//           <input
//             type="text"
//             id="latitude"
//             name="latitude"
//             className="form-control"
//             value={locationAttributes.latitude}
//             onChange={handleInputChange}
//           />
//         </div>
//         <div className="form-group">
//           <label htmlFor="longitude">Longitude</label>
//           <input
//             type="text"
//             id="longitude"
//             name="longitude"
//             className="form-control"
//             value={locationAttributes.longitude}
//             onChange={handleInputChange}
//           />
//         </div>
//         <button type="submit" className="btn btn-primary">Set Location</button>
//       </form>
//     </div>
//   );
// };

// export default SetMachineLocation;


import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { useLocation } from 'react-router-dom';
import { API_URL } from '../constants/Url';
import { useGlobalState } from '../constants/GlobalStateProvider';

const SetMachineLocation = () => {
  const [machineIds, setMachineIds] = useState([]);
  const [selectedMachineId, setSelectedMachineId] = useState('');
  const location = useLocation();
  const { getGlobal } = useGlobalState();
  const globalState = getGlobal();
  const [locationAttributes, setLocationAttributes] = useState({
    machine_name: '',
    location_name: '',
    zone_name: '',
    latitude: '',
    longitude: '',
  });

  useEffect(() => {
    if (globalState) {
      fetchMachineInfo(globalState);
    }
  }, [location, globalState]);

  const fetchMachineInfo = async (globalState) => {
    try {
      const response = await axios.get(`${API_URL}/machines/user/${globalState}`);
      const data = response.data;

      if (data.success) {
        setMachineIds(data.machine_ids);
      }
    } catch (error) {
      console.error('Error fetching machine info:', error);
    }
  };

  const handleInputChange = (event) => {
    const { name, value } = event.target;
    setLocationAttributes((prevAttributes) => ({
      ...prevAttributes,
      [name]: value,
    }));
  };

  const handleSubmit = async (event) => {
    event.preventDefault();

    const dataToSubmit = {
      user_id: globalState,
      machine_id: selectedMachineId,
      machine_name: locationAttributes.machine_name,
      location_name: locationAttributes.location_name,
      zone_name: locationAttributes.zone_name,
      latitude: locationAttributes.latitude,
      longitude: locationAttributes.longitude,
    };

    try {
      const response = await axios.post(`${API_URL}/machines/set_location`, dataToSubmit);
      if (response.data.success) {
        alert('Machine location updated successfully');
      } else {
        alert('Failed to update machine location');
      }
    } catch (error) {
      console.error('Error updating machine location:', error);
    }
  };

  const containerStyle = {
    padding: '20px',
    backgroundColor: '#f8f9fa',
    borderRadius: '10px',
    boxShadow: '0 4px 8px rgba(0, 0, 0, 0.1)',
    backgroundImage: 'url("assets/img/locationbg2.jpg")',
    backgroundSize: 'cover',
    backgroundPosition: 'center',
    maxWidth: '700px',
    margin: '0 auto',

  };

  const cardStyle = {
    backgroundColor: 'rgba(255, 255, 255, 0.5)',
    borderRadius: '10px',
    padding: '20px',
    marginBottom: '20px',
  };

  const buttonStyle = {
    display: 'inline-block',
    padding: '10px 30px',
    borderRadius: '50px',
    transition: '0.5s',
    marginTop: '10px',
    color: '#fff',
    backgroundColor: '#007bff',
    textDecoration: 'none',
    cursor: 'pointer',
    textAlign: 'center',
    border: 'none',
    fontSize: '16px',
  };

  const headingStyle = {
    textAlign: 'center',
    marginBottom: '20px',
    color: '#333',
  };

  const inputStyle = {
    width: '100%',
    padding: '10px',
    marginBottom: '10px',
    borderRadius: '5px',
    border: '1px solid #ccc',
  };

  return (
    <div style={containerStyle}>
      <h3 style={headingStyle}>Set Machine Location</h3>
      <div style={cardStyle}>
        <form onSubmit={handleSubmit}>
          <div style={cardStyle}>
            <label htmlFor="machineId">Select Machine ID</label>
            <select
              id="machineId"
              style={inputStyle}
              value={selectedMachineId}
              onChange={(e) => setSelectedMachineId(e.target.value)}
            >
              <option value="">Select Machine ID</option>
              {machineIds.map((machineId, index) => (
                <option key={index} value={machineId}>
                  {machineId}
                </option>
              ))}
            </select>
          </div>
          <div style={cardStyle}>
            <label htmlFor="machineName">Machine Name</label>
            <input
              type="text"
              id="machineName"
              name="machine_name"
              style={inputStyle}
              value={locationAttributes.machine_name}
              onChange={handleInputChange}
            />
          </div>
          <div style={cardStyle}>
            <label htmlFor="locationName">Location Name</label>
            <input
              type="text"
              id="locationName"
              name="location_name"
              style={inputStyle}
              value={locationAttributes.location_name}
              onChange={handleInputChange}
            />
          </div>
          <div style={cardStyle}>
            <label htmlFor="zoneName">Zone Name</label>
            <input
              type="text"
              id="zoneName"
              name="zone_name"
              style={inputStyle}
              value={locationAttributes.zone_name}
              onChange={handleInputChange}
            />
          </div>
          <div style={cardStyle}>
            <label htmlFor="latitude">Latitude</label>
            <input
              type="text"
              id="latitude"
              name="latitude"
              style={inputStyle}
              value={locationAttributes.latitude}
              onChange={handleInputChange}
            />
          </div>
          <div style={cardStyle}>
            <label htmlFor="longitude">Longitude</label>
            <input
              type="text"
              id="longitude"
              name="longitude"
              style={inputStyle}
              value={locationAttributes.longitude}
              onChange={handleInputChange}
            />
          </div>
          <div style={{ textAlign: 'center' }}>
            <button type="submit" style={buttonStyle}>Set Location</button>
          </div>
        </form>
      </div>
    </div>
  );
};

export default SetMachineLocation;

