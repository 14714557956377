import React, { useEffect, useState } from 'react';
import axios from 'axios';
import { useGlobalState } from '../constants/GlobalStateProvider';
import { FaBatteryFull, FaBatteryHalf, FaBatteryQuarter, FaBatteryThreeQuarters, FaBatteryEmpty } from 'react-icons/fa';
import { API_URL } from '../constants/Url';

const GeneralMachineTable = () => {
  const [machinesData, setMachinesData] = useState([]);
  const [channelInfo, setChannelInfo] = useState([]);
  const [machineIds, setMachineIds] = useState([]);
  const [fuelCapacities, setFuelCapacities] = useState({});
  const [searchTerm, setSearchTerm] = useState('');
  const [currentPage, setCurrentPage] = useState(1);
  const [rowsPerPage] = useState(5);
  const [emailStatus, setEmailStatus] = useState({});
  const { getGlobal } = useGlobalState();
  const globalState = getGlobal();

  useEffect(() => {
    if (globalState) {
      fetchMachineInfo(globalState);
    }
  }, [globalState]);

  useEffect(() => {
    if (machineIds.length > 0) {
      fetchData(machineIds);
    }
  }, [machineIds]);

  const fetchMachineInfo = async (globalState) => {
    try {
      const response = await axios.get(`${API_URL}/machines/user/${globalState}`);
      const data = response.data;

      if (data.success) {
        setMachineIds(data.machine_ids);

        const capacities = data.fuel_capacities.reduce((acc, { machine_id, fuel_capacity }) => {
          acc[machine_id] = fuel_capacity;
          return acc;
        }, {});
        setFuelCapacities(capacities);
      }
    } catch (error) {
      console.error('Error fetching user info:', error);
    }
  };

  const fetchData = async (machineIds) => {
    try {
      const apiPromises = machineIds.map((machineId) => {
        return axios.get(`${API_URL}/feeds/fetch-last-feeds?id=${machineId}&results=2`);
      });

      const results = await axios.all(apiPromises);
      const dataArray = results.map((result) => result.data);
      const channels = dataArray.map((data) => data.channel);
      const feeds = dataArray.map((data) => data.feeds);

      setChannelInfo(channels);
      setMachinesData(feeds);
    } catch (error) {
      console.error('Error fetching data:', error);
    }
  };

  const renderTableData = () => {
    const indexOfLastRow = currentPage * rowsPerPage;
    const indexOfFirstRow = indexOfLastRow - rowsPerPage;

    const filteredMachines = machinesData.map((feedArray, index) => {
      const currentFeed = feedArray[0];
      return { currentFeed, index };
    }).filter(({ currentFeed }) => {
      const { id, name, zone, area } = channelInfo[currentFeed.index] || {};
      const fullName = `${name}`.toLowerCase();
      return (
        searchTerm === '' ||
        fullName.includes(searchTerm.toLowerCase()) ||
        id?.toString().includes(searchTerm.toLowerCase()) ||
        zone?.toLowerCase().includes(searchTerm.toLowerCase()) ||
        area?.toLowerCase().includes(searchTerm.toLowerCase())
      );
    });

    const currentRows = filteredMachines.slice(indexOfFirstRow, indexOfLastRow);

    return currentRows.map(({ currentFeed, index }) => {
      const fuelCapacity = fuelCapacities[channelInfo[index]?.id];
      const fuelLevel = parseFloat(currentFeed.field1);
      const fuelPercentage = parseFloat(currentFeed.field3).toFixed(2);

      let fuelIcon;
      let fuelColor;
      if (fuelPercentage === null) {
        fuelIcon = <FaBatteryEmpty />;
        fuelColor = 'gray';
      } else if (fuelPercentage > 75) {
        fuelIcon = <FaBatteryFull />;
        fuelColor = 'green';
      } else if (fuelPercentage > 50) {
        fuelIcon = <FaBatteryThreeQuarters />;
        fuelColor = 'limegreen';
      } else if (fuelPercentage > 25) {
        fuelIcon = <FaBatteryHalf />;
        fuelColor = 'orange';
      } else {
        fuelIcon = <FaBatteryQuarter />;
        fuelColor = 'red';
      }

      return (
        <tr key={index}>
          <td>{channelInfo[index]?.id || 'N/A'}</td>
          <td>{channelInfo[index]?.name || 'N/A'}</td>
          <td>{currentFeed.created_at ? new Date(currentFeed.created_at).toLocaleString() : 'N/A'}</td>
          <td>{currentFeed.field1 || 'N/A'}</td>
          <td>{currentFeed.field2 || 'N/A'}</td>
          <td>
            {fuelPercentage !== null
              ? <span className="battery-icon" style={{ color: fuelColor }}>
                {fuelIcon} <b><span className="battery-text">{fuelPercentage}%</span></b>
              </span>
              : 'N/A'}
          </td>
          <td>{currentFeed.field4 || 'N/A'} v</td>
          <td>
            <button className={`btn ${currentFeed.field5 === "1" ? 'btn-success' : 'btn-danger'}`}>
              {currentFeed.field5 === "1" ? "On" : "Off"}
            </button>
          </td>
        </tr>
      );
    });
  };

  const nextPage = () => setCurrentPage((prevPage) => prevPage + 1);
  const prevPage = () => setCurrentPage((prevPage) => prevPage - 1);

  const totalPages = Math.ceil(machinesData.length / rowsPerPage);

  return (
    <div className="col-12">
      <div className="card recent-sales overflow-auto">
        <div className="card-body">
          <h5 className="card-title">Machines Status</h5>
          <div className="search-bar mb-3">
            <input
              type="text"
              className="form-control"
              placeholder="Search by ID or Machine Name"
              value={searchTerm}
              onChange={(event) => setSearchTerm(event.target.value)}
            />
          </div>
          {/* table-striped table-bordered */}
          <table className="table table-hover">  
            <thead className="table-info">
              <tr>
                <th scope="col">Machine ID</th>
                <th scope="col">Machine Name</th>
                <th scope="col">Last Update</th>
                <th scope="col">Total Capacity</th>
                <th scope="col">Current Capacity</th>
                <th scope="col">Fuel Level</th>
                <th scope="col">Battery</th>
                <th scope="col">Genset</th>
              </tr>
            </thead>
            <tbody>
              {renderTableData()}
            </tbody>
          </table>
          <div className="d-flex justify-content-between align-items-center mt-3">
            <button onClick={prevPage} disabled={currentPage === 1} className="btn btn-outline-secondary">Previous</button>
            <span className="page-no">Page {currentPage} of {totalPages}</span>
            <button onClick={nextPage} disabled={currentPage === totalPages} className="btn btn-outline-secondary">Next</button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default GeneralMachineTable;





















// import React, { useEffect, useState } from 'react';
// import axios from 'axios';
// import { useGlobalState } from '../constants/GlobalStateProvider';
// import { FaBatteryFull, FaBatteryHalf, FaBatteryQuarter, FaBatteryThreeQuarters, FaBatteryEmpty } from 'react-icons/fa';
// import { API_URL, EMAIL_URL } from '../constants/Url';

// const GeneralMachineTable = () => {
//   const [machinesData, setMachinesData] = useState([]);
//   const [channelInfo, setChannelInfo] = useState([]);
//   const [machineIds, setMachineIds] = useState([]);
//   const [fuelCapacities, setFuelCapacities] = useState({});
//   const [searchTerm, setSearchTerm] = useState('');
//   const [currentPage, setCurrentPage] = useState(1);
//   const [rowsPerPage] = useState(5);
//   const [emailStatus, setEmailStatus] = useState({});
//   const { getGlobal } = useGlobalState();
//   const globalState = getGlobal();

//   const NAME = 'Mehvish';
//   const MOBILE = '9087654321';
//   const EMAIL_RECEIVE = 'mehvishsheikh689@gmail.com';

//   useEffect(() => {
//     if (globalState) {
//       fetchMachineInfo(globalState);
//     }
//   }, [globalState]);

//   useEffect(() => {
//     if (machineIds.length > 0) {
//       fetchData(machineIds);
//     }
//   }, [machineIds]);

//   const fetchMachineInfo = async (globalState) => {
//     try {
//       const response = await axios.get(`${API_URL}/machines/user/${globalState}`);
//       const data = response.data;

//       if (data.success) {
//         setMachineIds(data.machine_ids);

//         const capacities = data.fuel_capacities.reduce((acc, { machine_id, fuel_capacity }) => {
//           acc[machine_id] = fuel_capacity;
//           return acc;
//         }, {});
//         setFuelCapacities(capacities);
//       }
//     } catch (error) {
//       console.error('Error fetching user info:', error);
//     }
//   };

//   const fetchData = async (machineIds) => {
//     try {
//       const apiPromises = machineIds.map((machineId) => {
//         return axios.get(`${API_URL}/feeds/fetch-last-feeds?id=${machineId}&results=2`);
//       });

//       const results = await axios.all(apiPromises);
//       const dataArray = results.map((result) => result.data);
//       const channels = dataArray.map((data) => data.channel);
//       const feeds = dataArray.map((data) => data.feeds);

//       setChannelInfo(channels);
//       setMachinesData(feeds);

//       console.log('Fetched feeds:', feeds); // Logging the fetched feeds

//     } catch (error) {
//       console.error('Error fetching data:', error);
//     }
//   };



//   const renderTableData = () => {
//     const indexOfLastRow = currentPage * rowsPerPage;
//     const indexOfFirstRow = indexOfLastRow - rowsPerPage;

//     const filteredMachines = machinesData.map((feedArray, index) => {
//       const currentFeed = feedArray[0];
//       return { currentFeed, index };
//     }).filter(({ currentFeed }) => {
//       const { id, name, zone, area } = channelInfo[currentFeed.index] || {};
//       const fullName = `${name}`.toLowerCase();
//       return (
//         searchTerm === '' ||
//         fullName.includes(searchTerm.toLowerCase()) ||
//         id?.toString().includes(searchTerm.toLowerCase()) ||
//         zone?.toLowerCase().includes(searchTerm.toLowerCase()) ||
//         area?.toLowerCase().includes(searchTerm.toLowerCase())
//       );
//     });

//     const currentRows = filteredMachines.slice(indexOfFirstRow, indexOfLastRow);

//     return currentRows.map(({ currentFeed, index }) => {
//       const fuelCapacity = fuelCapacities[channelInfo[index]?.id];
//       const fuelLevel = parseFloat(currentFeed.field1);
//       const fuelPercentage = parseFloat(currentFeed.field3);

//       let fuelIcon;
//       let fuelColor;
//       if (fuelPercentage === null) {
//         fuelIcon = <FaBatteryEmpty />;
//         fuelColor = 'gray';
//       } else if (fuelPercentage > 75) {
//         fuelIcon = <FaBatteryFull />;
//         fuelColor = 'green';
//       } else if (fuelPercentage > 50) {
//         fuelIcon = <FaBatteryThreeQuarters />;
//         fuelColor = 'limegreen';
//       } else if (fuelPercentage > 25) {
//         fuelIcon = <FaBatteryHalf />;
//         fuelColor = 'orange';
//       } else {
//         fuelIcon = <FaBatteryQuarter />;
//         fuelColor = 'red';
//       }

//       return (
//         <tr key={index}>
//           <td>{channelInfo[index]?.id || 'N/A'}</td>
//           <td>{channelInfo[index]?.name || 'N/A'}</td>
//           <td>{currentFeed.created_at ? new Date(currentFeed.created_at).toLocaleString() : 'N/A'}</td>
//           <td>{currentFeed.field1 || 'N/A'}</td>
//           <td>{currentFeed.field2 || 'N/A'}</td>
//           <td>
//             {fuelPercentage !== null
//               ? <span className="battery-icon" style={{ color: fuelColor }}>
//                 {fuelIcon} <b><span className="battery-text">{currentFeed.field3}%</span></b>
//               </span>
//               : 'NA'}
//           </td>

//           <td>{currentFeed.field4 || 'N/A'}</td>

//           <td>
//             <button className={`btn ${currentFeed.field5 === "1" ? 'btn-success' : 'btn-danger'}`}>
//               {currentFeed.field5 === "1" ? "On" : "Off"}
//             </button>
//           </td>

//           <td>
//             {emailStatus[channelInfo[index]?.id] && (
//               <span style={{ color: emailStatus[channelInfo[index]?.id] === 'Error sending email' ? 'red' : 'green' }}>
//                 {emailStatus[channelInfo[index]?.id]} 
//               </span>
//             )}
//           </td>

//         </tr>
//       );
//     });
//   };

//   const nextPage = () => setCurrentPage((prevPage) => prevPage + 1);
//   const prevPage = () => setCurrentPage((prevPage) => prevPage - 1);

//   const totalPages = Math.ceil(machinesData.length / rowsPerPage);

//   return (
//     <div className="col-12">
//       <div className="card recent-sales overflow-auto">
//         <div className="filter">
//           <a className="icon" href="#" data-bs-toggle="dropdown">
//             <i className="bi bi-three-dots" />
//           </a>
//           <ul className="dropdown-menu dropdown-menu-end dropdown-menu-arrow">
//             <li className="dropdown-header text-start">
//               <h6>Filter</h6>
//             </li>
//             <li>
//               <a className="dropdown-item" href="#">
//                 Today
//               </a>
//             </li>
//             <li>
//               <a className="dropdown-item" href="#">
//                 This Month
//               </a>
//             </li>
//             <li>
//               <a className="dropdown-item" href="#">
//                 This Year
//               </a>
//             </li>
//           </ul>
//         </div>
//         <div className="card-body">
//           <h5 className="card-title">
//             Machines Status : {machineIds.join(', ')}
//           </h5>
//           <div>
//             <div className="search-bar">
//               <input
//                 type="text"
//                 className="form-control"
//                 placeholder="Search by ID or Machine Name"
//                 value={searchTerm}
//                 onChange={(event) => setSearchTerm(event.target.value)}
//               />
//             </div>
//             <br />
//             <table className="table table-borderless datatable">
//               <thead>
//                 <tr>
//                   <th scope="col">Machine ID</th>
//                   <th scope="col">Machine Name</th>
//                   <th scope="col">Last Update Time</th>
//                   <th scope="col">Total Capacity</th>
//                   <th scope="col">Current Capacity</th>
//                   <th scope="col">Fuel Percentage</th>
//                   <th scope="col">Battery Voltage</th>
//                   <th scope="col">Genset</th>
//                   <th scope="col">Email Status</th>
//                 </tr>
//               </thead>
//               <tbody>
//                 {renderTableData()}
//               </tbody>
//             </table>
//             <div className="pagination">
//               <div className="d-flex justify-content-between align-items-center">
//                 <div>
//                   <button onClick={prevPage} disabled={currentPage === 1} className="btn btn-outline-secondary me-2">Previous</button>
//                   <button onClick={nextPage} disabled={currentPage === totalPages} className="btn btn-outline-secondary">Next</button>
//                 </div>
//               </div>
//               <span className="page-no">Page {currentPage} of {totalPages}</span>
//             </div>
//           </div>
//         </div>
//       </div>
//     </div>
//   );
// };

// export default GeneralMachineTable;






// import React, { useEffect, useState } from 'react';
// import axios from 'axios';
// import { useGlobalState } from '../constants/GlobalStateProvider';
// import { FaBatteryFull, FaBatteryHalf, FaBatteryQuarter, FaBatteryThreeQuarters, FaBatteryEmpty } from 'react-icons/fa';
// import { API_URL } from '../constants/Url';

// const GeneralMachineTable = () => {
//   const [machinesData, setMachinesData] = useState([]);
//   const [channelInfo, setChannelInfo] = useState([]);
//   const [machineIds, setMachineIds] = useState([]);
//   const [fuelPercentages, setFuelPercentages] = useState({});
//   const [fuelCapacities, setFuelCapacities] = useState({});
//   const [searchTerm, setSearchTerm] = useState('');
//   const [currentPage, setCurrentPage] = useState(1);
//   const [rowsPerPage] = useState(5);

//   const { getGlobal } = useGlobalState();
//   const globalState = getGlobal();

//   useEffect(() => {
//     if (globalState) {
//       fetchMachineInfo(globalState);
//     }
//   }, [globalState]);

//   useEffect(() => {
//     if (machineIds.length > 0) {
//       fetchData(machineIds);
//     }
//   }, [machineIds]);

//   const fetchMachineInfo = async (globalState) => {
//     try {
//       const response = await axios.get(`${API_URL}/machines/user/${globalState}`);
//       const data = response.data;

//       if (data.success) {
//         setMachineIds(data.machine_ids);

//         const capacities = data.fuel_capacities.reduce((acc, { machine_id, fuel_capacity }) => {
//           acc[machine_id] = fuel_capacity;
//           return acc;
//         }, {});
//         setFuelCapacities(capacities);
//       }
//     } catch (error) {
//       console.error('Error fetching user info:', error);
//     }
//   };

//   const fetchData = async (machineIds) => {
//     try {
//       const apiPromises = machineIds.map((machineId) => {
//         return axios.get(`${API_URL}/feeds/fetch-last-feeds?id=${machineId}&results=1`);
//       });

//       const results = await axios.all(apiPromises);
//       const dataArray = results.map((result) => result.data);
//       const channels = dataArray.map((data) => data.channel);
//       const feeds = dataArray.map((data) => data.feeds[0] || {}); // Handling case where feeds might be empty

//       setChannelInfo(channels);
//       setMachinesData(feeds);
//     } catch (error) {
//       console.error('Error fetching data:', error);
//     }
//   };

//   const renderTableData = () => {
//     const indexOfLastRow = currentPage * rowsPerPage;
//     const indexOfFirstRow = indexOfLastRow - rowsPerPage;

//     const filteredMachines = machinesData.filter((machine, index) => {
//       const { id, name, zone, area } = channelInfo[index] || {};
//       const fullName = `${name}`.toLowerCase();
//       return (
//         searchTerm === '' ||
//         fullName.includes(searchTerm.toLowerCase()) ||
//         id?.toString().includes(searchTerm.toLowerCase()) ||
//         zone?.toLowerCase().includes(searchTerm.toLowerCase()) ||
//         area?.toLowerCase().includes(searchTerm.toLowerCase())
//       );
//     });

//     const currentRows = filteredMachines.slice(indexOfFirstRow, indexOfLastRow);

//     return currentRows.map((machine, index) => {
//       const fuelCapacity = fuelCapacities[channelInfo[index]?.id];
//       const fuelLevel = parseFloat(machine.field1);
//       const fuelPercentage = parseFloat(machine.field3);

//       let fuelIcon;
//       let fuelColor;
//       if (fuelPercentage === null) {
//         fuelIcon = <FaBatteryEmpty />;
//         fuelColor = 'gray';
//       } else if (fuelPercentage > 75) {
//         fuelIcon = <FaBatteryFull />;
//         fuelColor = 'green';
//       } else if (fuelPercentage > 50) {
//         fuelIcon = <FaBatteryThreeQuarters />;
//         fuelColor = 'limegreen';
//       } else if (fuelPercentage > 25) {
//         fuelIcon = <FaBatteryHalf />;
//         fuelColor = 'orange';
//       } else {
//         fuelIcon = <FaBatteryQuarter />;
//         fuelColor = 'red';
//       }

//       return (
//         <tr key={index}>
//           <td>{channelInfo[index]?.id || 'N/A'}</td>
//           <td>{channelInfo[index]?.name || 'N/A'}</td>
//           <td>{machine.created_at ? new Date(machine.created_at).toLocaleString() : 'N/A'}</td>
//           <td>{machine.field1 || 'N/A'}</td>
//           <td>{machine.field2 || 'N/A'}</td>
//           <td>
//             {fuelPercentage !== null
//               ? <span className="battery-icon" style={{ color: fuelColor }}>
//                 {fuelIcon} <b><span className="battery-text">{machine.field3}%</span></b>
//               </span>
//               : 'NA'}
//           </td>
//           <td>{machine.field4 || 'N/A'}</td>
//           <td>
//             <button className={`btn ${machine.field5 === "1" ? 'btn-success' : 'btn-danger'}`}>
//               {machine.field5 === "1" ? "On" : "Off"}
//             </button>
//           </td>
//         </tr>
//       );
//     });
//   };

//   const nextPage = () => setCurrentPage((prevPage) => prevPage + 1);
//   const prevPage = () => setCurrentPage((prevPage) => prevPage - 1);

//   const totalPages = Math.ceil(machinesData.length / rowsPerPage);

//   return (
//     <div className="col-12">
//       <div className="card recent-sales overflow-auto">
//         <div className="filter">
//           <a className="icon" href="#" data-bs-toggle="dropdown">
//             <i className="bi bi-three-dots" />
//           </a>
//           <ul className="dropdown-menu dropdown-menu-end dropdown-menu-arrow">
//             <li className="dropdown-header text-start">
//               <h6>Filter</h6>
//             </li>
//             <li>
//               <a className="dropdown-item" href="#">
//                 Today
//               </a>
//             </li>
//             <li>
//               <a className="dropdown-item" href="#">
//                 This Month
//               </a>
//             </li>
//             <li>
//               <a className="dropdown-item" href="#">
//                 This Year
//               </a>
//             </li>
//           </ul>
//         </div>
//         <div className="card-body">
//           <h5 className="card-title">
//             Machines Status : {machineIds.join(', ')}
//           </h5>
//           <div>
//             <div className="search-bar">
//               <input
//                 type="text"
//                 className="form-control"
//                 placeholder="Search by ID or Machine Name"
//                 value={searchTerm}
//                 onChange={(event) => setSearchTerm(event.target.value)}
//               />
//             </div>
//             <br />
//             <table className="table table-borderless datatable">
//               <thead>
//                 <tr>
//                   <th scope="col">Machine ID</th>
//                   <th scope="col">Machine Name</th>
//                   <th scope="col">Last Update Time</th>
//                   <th scope="col">Total Capacity</th>
//                   <th scope="col">Current Capacity</th>
//                   <th scope="col">Fuel Percentage</th>
//                   <th scope="col">Battery Voltage</th>
//                   <th scope="col">Genset</th>
//                 </tr>
//               </thead>
//               <tbody>
//                 {renderTableData()}
//               </tbody>
//             </table>
//             <div className="pagination">
//               <div className="d-flex justify-content-between align-items-center">
//                 <div>
//                   <button onClick={prevPage} disabled={currentPage === 1} className="btn btn-outline-secondary me-2">Previous</button>
//                   <button onClick={nextPage} disabled={currentPage === totalPages} className="btn btn-outline-secondary">Next</button>
//                 </div>
//               </div>
//               <span className="page-no">Page {currentPage} of {totalPages}</span>
//             </div>
//           </div>
//         </div>
//       </div>
//     </div>
//   );
// };

// export default GeneralMachineTable;


// import React, { useEffect, useState } from 'react';
// import axios from 'axios';
// import { useGlobalState } from '../constants/GlobalStateProvider';
// import { FaBatteryFull, FaBatteryHalf, FaBatteryQuarter, FaBatteryThreeQuarters, FaBatteryEmpty, FaMapMarkerAlt } from 'react-icons/fa';
// import { API_URL } from '../constants/Url';

// const CACHE_TIMEOUT = 30 * 1000; // 30 seconds

// const GeneralMachineTable = () => {
//   const [machinesData, setMachinesData] = useState([]);
//   const [channelInfo, setChannelInfo] = useState([]);
//   const [machineIds, setMachineIds] = useState([]);
//   const [fuelPercentages, setFuelPercentages] = useState({});
//   const [fuelCapacities, setFuelCapacities] = useState({});
//   const [searchTerm, setSearchTerm] = useState('');
//   const [currentPage, setCurrentPage] = useState(1);
//   const [rowsPerPage] = useState(5);

//   const { getGlobal } = useGlobalState();
//   const globalState = getGlobal();

//   useEffect(() => {
//     if (globalState) {
//       fetchMachineInfo(globalState);
//     }
//   }, [globalState]);

//   useEffect(() => {
//     if (machineIds.length > 0) {
//       fetchData(machineIds);
//     }
//   }, [machineIds]);

//   const fetchMachineInfo = async (globalState) => {
//     try {
//       const response = await axios.get(`${API_URL}/machines/user/${globalState}`);
//       const data = response.data;

//       if (data.success) {
//         setMachineIds(data.machine_ids);

//         const capacities = data.fuel_capacities.reduce((acc, { machine_id, fuel_capacity }) => {
//           acc[machine_id] = fuel_capacity;
//           return acc;
//         }, {});
//         setFuelCapacities(capacities);
//       }
//     } catch (error) {
//       console.error('Error fetching user info:', error);
//     }
//   };

//   const fetchData = async (machineIds) => {
//     const cachedData = JSON.parse(localStorage.getItem('machineData')) || {};
//     const lastFetchTime = cachedData.timestamp || 0;

//     if (Date.now() - lastFetchTime < CACHE_TIMEOUT) {
//       setChannelInfo(cachedData.channels);
//       setMachinesData(cachedData.feeds);
//       // calculateFuelPercentages(cachedData.feeds, fuelCapacities);
//       return;
//     }

//     let timeoutReached = false;
//     const timeout = setTimeout(() => {
//       timeoutReached = true;
//       setChannelInfo(cachedData.channels);
//       setMachinesData(cachedData.feeds);
//       // calculateFuelPercentages(cachedData.feeds, fuelCapacities);
//     }, CACHE_TIMEOUT);

//     // try {
//     //   const apiPromises = machineIds.map((machineId) => {
//     //     const apiKey = machineId === '2422147' ? 'E6V6BU2L78L7YYAZ' : 'YR2MHT97ZBXYIL4Y';
//     //     return axios.get(`https://api.thingspeak.com/channels/${machineId}/feeds.json?api_key=${apiKey}&results=1`);
//     //   });
//       try {
//         const apiPromises = machineIds.map((machineId) => {
//           return axios.get(`${API_URL}/feeds/fetch-last-feeds?id=${machineId}&results=1`);
//         });

//       const results = await axios.all(apiPromises);
//       clearTimeout(timeout);

//       if (!timeoutReached) {
//         const dataArray = results.map((result) => result.data);
//         const channels = dataArray.map((data) => data.channel);
//         const feeds = dataArray.map((data) => data.feeds[0] || {}); // Handling case where feeds might be empty

//         const newData = {
//           timestamp: Date.now(),
//           channels,
//           feeds,
//         };

//         localStorage.setItem('machineData', JSON.stringify(newData));

//         setChannelInfo(channels);
//         setMachinesData(feeds);
//         // calculateFuelPercentages(feeds, fuelCapacities);
//       }
//     } catch (error) {
//       console.error('Error fetching data:', error);
//     }
//   };

//   const renderTableData = () => {
//     const indexOfLastRow = currentPage * rowsPerPage;
//     const indexOfFirstRow = indexOfLastRow - rowsPerPage;

//     const filteredMachines = machinesData.filter((machine, index) => {
//       const { id, name, zone, area } = channelInfo[index] || {};
//       const fullName = `${name}`.toLowerCase();
//       return (
//         searchTerm === '' ||
//         fullName.includes(searchTerm.toLowerCase()) ||
//         id?.toString().includes(searchTerm.toLowerCase()) ||
//         zone?.toLowerCase().includes(searchTerm.toLowerCase()) ||
//         area?.toLowerCase().includes(searchTerm.toLowerCase())
//       );
//     });

//     const currentRows = filteredMachines.slice(indexOfFirstRow, indexOfLastRow);

//     return currentRows.map((machine, index) => {
//       const fuelCapacity = fuelCapacities[channelInfo[index]?.id];
//       const fuelLevel = parseFloat(machine.field1);
//       const fuelPercentage = parseFloat(machine.field3);

//       let fuelIcon;
//       let fuelColor;
//       if (fuelPercentage === null) {
//         fuelIcon = <FaBatteryEmpty />;
//         fuelColor = 'gray';
//       } else if (fuelPercentage > 75) {
//         fuelIcon = <FaBatteryFull />;
//         fuelColor = 'green';
//       } else if (fuelPercentage > 50) {
//         fuelIcon = <FaBatteryThreeQuarters />;
//         fuelColor = 'limegreen';
//       } else if (fuelPercentage > 25) {
//         fuelIcon = <FaBatteryHalf />;
//         fuelColor = 'orange';
//       } else {
//         fuelIcon = <FaBatteryQuarter />;
//         fuelColor = 'red';
//       }

//       return (
//         <tr key={index}>
//           <td>{channelInfo[index]?.id || 'N/A'}</td>
//           <td>{channelInfo[index]?.name || 'N/A'}</td>
//           {/* <td>{channelInfo[index]?.zone || 'N/A'}</td>
//           <td>{channelInfo[index]?.area || 'N/A'}</td> */}
          
//           <td>{machine.created_at ? new Date(machine.created_at).toLocaleString() : 'N/A'}</td>
//           <td>{machine.field1 || 'N/A'}</td>
//           <td>{machine.field2 || 'N/A'}</td>
//           <td>
//             {fuelPercentage !== null
//               ? <span className="battery-icon" style={{ color: fuelColor }}>
//                 {fuelIcon} <b><span className="battery-text">{machine.field3}%</span></b>
//               </span>
//               : 'NA'}
//           </td>
//           <td>{machine.field4 || 'N/A'}</td>
//           <td>
//             <button className={`btn ${machine.field5 === "1" ? 'btn-success' : 'btn-danger'}`}>
//               {machine.field5 === "1" ? "On" : "Off"}
//             </button>
//           </td>
//           {/* <td>
//             {channelInfo[index]?.latitude}, {channelInfo[index]?.longitude || 'N/A'}
//             <FaMapMarkerAlt />
//           </td> */}
//         </tr>
//       );
//     });
//   };

//   const nextPage = () => setCurrentPage((prevPage) => prevPage + 1);
//   const prevPage = () => setCurrentPage((prevPage) => prevPage - 1);

//   const totalPages = Math.ceil(machinesData.length / rowsPerPage);

//   return (
//     <div className="col-12">
//       <div className="card recent-sales overflow-auto">
//         <div className="filter">
//           <a className="icon" href="#" data-bs-toggle="dropdown">
//             <i className="bi bi-three-dots" />
//           </a>
//           <ul className="dropdown-menu dropdown-menu-end dropdown-menu-arrow">
//             <li className="dropdown-header text-start">
//               <h6>Filter</h6>
//             </li>
//             <li>
//               <a className="dropdown-item" href="#">
//                 Today
//               </a>
//             </li>
//             <li>
//               <a className="dropdown-item" href="#">
//                 This Month
//               </a>
//             </li>
//             <li>
//               <a className="dropdown-item" href="#">
//                 This Year
//               </a>
//             </li>
//           </ul>
//         </div>
//         <div className="card-body">
//           <h5 className="card-title">
//             Machines Status : {machineIds.join(', ')}
//           </h5>
//           <div>
//             <div className="search-bar">
//               <input
//                 type="text"
//                 className="form-control"
//                 placeholder="Search by ID or Machine Name"
//                 value={searchTerm}
//                 onChange={(event) => setSearchTerm(event.target.value)}
//               />
//             </div>
//             <br />
//             <table className="table table-borderless datatable">
//               <thead>
//                 <tr>
//                 <th scope="col">Machine ID</th>
//                <th scope="col">Machine Name</th>
//                 <th scope="col">Last Update Time</th>
//                <th scope="col">Total Capacity</th>
//                <th scope="col">Current Capacity</th>
//              <th scope="col">Fuel Percentage</th>
//              <th scope="col">Battery Voltage</th>
//                <th scope="col">Genset</th>
//                {/* <th scope="col">Location</th> */}
//                 </tr>
//               </thead>
//               <tbody>
//                 {renderTableData()}
//               </tbody>
//             </table>
//             <div className="pagination">
//               <div className="d-flex justify-content-between align-items-center">
//                 <div>
//                   <button onClick={prevPage} disabled={currentPage === 1} className="btn btn-outline-secondary me-2">Previous</button>
//                   <button onClick={nextPage} disabled={currentPage === totalPages} className="btn btn-outline-secondary">Next</button>
//                 </div>
//               </div>
//               <span className="page-no">Page {currentPage} of {totalPages}</span>
//             </div>
//           </div>
//         </div>
//       </div>
//     </div>
//   );
// };

// export default GeneralMachineTable;
