import React, { useEffect, useState } from 'react';
import { MapContainer, TileLayer, Marker, Popup, useMap } from 'react-leaflet';
import L from 'leaflet';
import 'leaflet/dist/leaflet.css';
import axios from 'axios';

// Fix for missing marker icon issue in Leaflet with Webpack
import markerIcon2x from 'leaflet/dist/images/marker-icon-2x.png';
import markerIcon from 'leaflet/dist/images/marker-icon.png';
import markerShadow from 'leaflet/dist/images/marker-shadow.png';

delete L.Icon.Default.prototype._getIconUrl;

L.Icon.Default.mergeOptions({
  iconRetinaUrl: markerIcon2x,
  iconUrl: markerIcon,
  shadowUrl: markerShadow,
});

const MapComponent = ({ latitude, longitude, locationName }) => {
  const [position, setPosition] = useState([latitude, longitude]);
  const [popupMessage, setPopupMessage] = useState('');
  const [zoomLevel, setZoomLevel] = useState(13); // Initial zoom level

  useEffect(() => {
    if (latitude && longitude) {
      setPosition([latitude, longitude]);
      setPopupMessage(locationName || `Latitude: ${latitude}, Longitude: ${longitude}`);
      setZoomLevel(18); // Set zoom level when coordinates are provided
    } else if (locationName) {
      const fetchLocation = async () => {
        try {
          const response = await axios.get(`https://nominatim.openstreetmap.org/search`, {
            params: {
              q: locationName,
              format: 'json',
              limit: 1,
            },
          });

          if (response.data.length > 0) {
            const { lat, lon, display_name } = response.data[0];
            setPosition([parseFloat(lat), parseFloat(lon)]);
            setPopupMessage(display_name);
            setZoomLevel(15); // Set zoom level when location name is resolved
          } else {
            setPopupMessage('Location not found. Showing default location.');
            setPosition([51.505, -0.09]); // Default position (London)
            setZoomLevel(13); // Default zoom level
          }
        } catch (error) {
          console.error('Error fetching location:', error);
          setPopupMessage('Error fetching location data.');
          setPosition([51.505, -0.09]); // Default position (London)
          setZoomLevel(13); // Default zoom level
        }
      };

      fetchLocation();
    }
  }, [latitude, longitude, locationName]);

  const handleCopyLocation = () => {
    const locationText = `Latitude: ${position[0]}, Longitude: ${position[1]}`;
    navigator.clipboard.writeText(locationText).then(() => {
      alert('Location copied to clipboard');
    });
  };

  const handleShareLocation = () => {
    const locationUrl = `https://www.openstreetmap.org/?mlat=${position[0]}&mlon=${position[1]}&zoom=${zoomLevel}`;
    const locationText = `Check out this location: ${locationUrl}`;
    if (navigator.share) {
      navigator.share({
        title: 'Location Share',
        text: locationText,
        url: locationUrl,
      }).catch((error) => {
        console.error('Error sharing location:', error);
      });
    } else {
      alert(`Copy this link to share: ${locationUrl}`);
    }
  };

  return (
    <div style={{ position: 'relative', width: '100%', height: '400px', borderRadius: '8px', overflow: 'hidden' }}>
      <MapContainer center={position} zoom={zoomLevel} style={{ height: '100%', width: '100%', transition: 'transform 0.3s ease-in-out' }}>
        <MapUpdater position={position} zoomLevel={zoomLevel} />
        <TileLayer
          url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
          attribution='&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors'
        />
        <Marker position={position}>
          <Popup>{popupMessage}</Popup>
        </Marker>
      </MapContainer>
      <div style={{ position: 'absolute', bottom: '20px', right: '20px', display: 'flex', gap: '10px', zIndex: 1000 }}>
        <button
          onClick={handleCopyLocation}
          style={{
            padding: '10px 20px',
            borderRadius: '5px',
            backgroundColor: '#007bff',
            color: 'white',
            border: 'none',
            cursor: 'pointer',
            transition: 'background-color 0.3s ease, transform 0.3s ease',
          }}
        >
          Copy Location
        </button>
        <button
          onClick={handleShareLocation}
          style={{
            padding: '10px 20px',
            borderRadius: '5px',
            backgroundColor: '#28a745',
            color: 'white',
            border: 'none',
            cursor: 'pointer',
            transition: 'background-color 0.3s ease, transform 0.3s ease',
          }}
        >
          Share Location
        </button>
      </div>
    </div>
  );
};

const MapUpdater = ({ position, zoomLevel }) => {
  const map = useMap();

  useEffect(() => {
    map.setView(position, zoomLevel, {
      animate: true,
      duration: 0.5,
    });
  }, [position, zoomLevel, map]);

  return null;
};

export default MapComponent;








// import React, { useEffect, useState } from 'react';
// import { MapContainer, TileLayer, Marker, Popup, useMap } from 'react-leaflet';
// import L from 'leaflet';
// import 'leaflet/dist/leaflet.css';
// import axios from 'axios';

// // Fix for missing marker icon issue in Leaflet with Webpack
// import markerIcon2x from 'leaflet/dist/images/marker-icon-2x.png';
// import markerIcon from 'leaflet/dist/images/marker-icon.png';
// import markerShadow from 'leaflet/dist/images/marker-shadow.png';

// delete L.Icon.Default.prototype._getIconUrl;

// L.Icon.Default.mergeOptions({
//   iconRetinaUrl: markerIcon2x,
//   iconUrl: markerIcon,
//   shadowUrl: markerShadow,
// });

// const MAPBOX_ACCESS_TOKEN = 'YOUR_MAPBOX_ACCESS_TOKEN';

// const MapComponent = ({ latitude, longitude, locationName }) => {
//   const [position, setPosition] = useState([latitude, longitude]);

//   useEffect(() => {
//     if (latitude && longitude) {
//       setPosition([latitude, longitude]);
//     } else if (locationName) {
//       const fetchLocation = async () => {
//         try {
//           const response = await axios.get(`https://nominatim.openstreetmap.org/search`, {
//             params: {
//               q: locationName,
//               format: 'json',
//             },
//           });
//           const { lat, lon } = response.data[0];
//           setPosition([parseFloat(lat), parseFloat(lon)]);
//         } catch (error) {
//           console.error('Error fetching location:', error);
//         }
//       };

//       fetchLocation();
//     }
//   }, [latitude, longitude, locationName]);

//   return (
//     <MapContainer center={position} zoom={13} style={{ height: '400px', width: '100%' }}>
//       <MapUpdater position={position} />
//       <TileLayer
//         url={`https://api.mapbox.com/styles/v1/{id}/tiles/{z}/{x}/{y}?access_token=${MAPBOX_ACCESS_TOKEN}`}
//         id="mapbox/streets-v11"
//         tileSize={512}
//         zoomOffset={-1}
//         attribution='&copy; <a href="https://www.mapbox.com/about/maps/">Mapbox</a> contributors'
//       />
//       <Marker position={position}>
//         <Popup>
//           {locationName || `Latitude: ${position[0]}, Longitude: ${position[1]}`}
//         </Popup>
//       </Marker>
//     </MapContainer>
//   );
// };

// const MapUpdater = ({ position }) => {
//   const map = useMap();

//   useEffect(() => {
//     map.setView(position, map.getZoom());
//   }, [position, map]);

//   return null;
// };

// export default MapComponent;
