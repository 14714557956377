import React from 'react'
import Table_totalmachines from './Table_totalmachines'
import DataCards from './DataCards'
import LineChartOverall from './LineChartOverall'
import GeneralMachineTable from '../Machines/GeneralMachineTable'
import SpecificMachineTable from '../Machines/SpecificMachineTable'
import { useGlobalState } from '../constants/GlobalStateProvider'
import GensetMonitoringTable from '../Machines/GensetMonitoringTable'
import SeparateApi from '../Machines/SeparateApi'
import LocationButton from '../location1/LocationButton'
import { Link, useNavigate } from 'react-router-dom';

function Dashboard() {
  const { getGlobal, setGlobal } = useGlobalState();
  const globalState = getGlobal();
  const navigate = useNavigate();

  const ctaBtnContainerStyle = {
    textAlign: 'center',
  };

  const ctaBtnStyle = {
    display: 'inline-block',
    padding: '10px 30px',
    borderRadius: '50px',
    transition: '0.5s',
    marginTop: '2px',
    marginLeft: '565px',
    color: '#fff',
    width: '270px',
    backgroundColor: '#007bff',
    textDecoration: 'none',
    cursor: 'pointer',
  };

  const handleNotification = () =>{
    navigate('/IndexNotifications')
  }

  return (
    <>
      <main id="main" className="main">
        <div className="pagetitle">
          {/* <h1>Dashboard</h1> */}

          <nav>
            <ol className="breadcrumb">
            <div style={ctaBtnContainerStyle}>
              {/* <button style={ctaBtnStyle} >
                Set Location
              </button> */}
            </div>
              {/* <li className="breadcrumb-item">
                <a href="index.html">Home</a>
              </li> */}
              {/* <li className="breadcrumb-item active">Dashboard</li> */}
            </ol>
          </nav>
        </div>

        <section className="section dashboard">
          <div className="row">
         
          <LineChartOverall/>
          <DataCards />
            <GeneralMachineTable/>
            <LocationButton/>
            {/* <SeparateApi/> */}
            {/* <SpecificMachineTable/>
            <GensetMonitoringTable/> */}
            {/* <Table_totalmachines /> */}

          </div>


        </section>
      </main>

    </>

  )
}

export default Dashboard