import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { API_URL } from '../constants/Url';
import GensetInfo from './GensetInfo';
import GensetCharts from './GensetCharts';
import GensetTable from './GensetTable';

function GensetStatus({ machineId }) {
  const [machineData, setMachineData] = useState(null);
  const [selectedDay, setSelectedDay] = useState('all');
  const [selectedRange, setSelectedRange] = useState(7);
  const [showLineChart, setShowLineChart] = useState(true);
  const [showPieChart, setShowPieChart] = useState(true);
  const [showBarChart, setShowBarChart] = useState(true);
  const [dailyOnTime, setDailyOnTime] = useState([]);
  const [hourlyOnTime, setHourlyOnTime] = useState([]);

  useEffect(() => {
    const fetchMachineData = async () => {
      try {
        const response = await axios.get(`${API_URL}/feeds/fetch-last-feeds?id=${machineId}&results=1000`);
        setMachineData(response.data);
        calculateDailyOnTime(response.data.feeds.reverse()); // Reverse the order of feeds
      } catch (error) {
        console.error('Error fetching machine data:', error);
      }
    };

    fetchMachineData();
  }, [machineId]);

  useEffect(() => {
    if (machineData) {
      const filteredFeeds = filterDataByDay(machineData.feeds, selectedDay);
      calculateDailyOnTime(filteredFeeds);
      calculateHourlyOnTime(filteredFeeds);
    }
  }, [selectedDay, machineData]);

  useEffect(() => {
    if (machineData) {
      const filteredFeeds = filterDataByRange(machineData.feeds, selectedRange);
      calculateDailyOnTime(filteredFeeds);
      calculateHourlyOnTime(filteredFeeds);
    }
  }, [selectedRange, machineData]);

  const calculateDurationAndFuel = (feeds) => {
    if (!feeds || feeds.length === 0) return { totalDuration: 0, totalFuelConsumed: 0 };

    let totalDuration = 0;
    let totalFuelConsumed = 0;

    for (let i = 0; i < feeds.length - 1; i++) {
      if (feeds[i].field5 === '1' && feeds[i + 1].field5 === '0') {
        const startTime = new Date(feeds[i].created_at);
        const endTime = new Date(feeds[i + 1].created_at);
        const duration = (endTime - startTime) / (1000 * 60 * 60); // in hours
        const fuelConsumed = feeds[i].field3 - feeds[i + 1].field3; // assuming field3 is fuel level

        totalDuration += duration;
        totalFuelConsumed += fuelConsumed;
      }
    }

    return { totalDuration, totalFuelConsumed };
  };

  const calculateDailyOnTime = (feeds) => {
    if (!feeds || feeds.length === 0) return [];

    const onTimePerDay = feeds.reduce((acc, feed, index) => {
      const date = new Date(feed.created_at).toISOString().split('T')[0];
      if (!acc[date]) {
        acc[date] = { date, onTime: 0 };
      }

      if (feed.field5 === '1' && feeds[index + 1] && feeds[index + 1].field5 === '0') {
        const startTime = new Date(feed.created_at);
        const endTime = new Date(feeds[index + 1].created_at);
        const duration = (endTime - startTime) / (1000 * 60 * 60); // in hours
        acc[date].onTime += duration;
      }

      return acc;
    }, {});

    const onTimeArray = Object.values(onTimePerDay);
    setDailyOnTime(onTimeArray);
  };

  const calculateHourlyOnTime = (feeds) => {
    if (!feeds || feeds.length === 0 || selectedDay === 'all') return [];

    const onTimePerHour = feeds.reduce((acc, feed, index) => {
      const date = new Date(feed.created_at).toISOString().split('T')[0];
      if (date !== selectedDay) return acc;

      const hour = new Date(feed.created_at).getHours();
      if (!acc[hour]) {
        acc[hour] = { hour, onTime: 0 };
      }

      if (feed.field5 === '1' && feeds[index + 1] && feeds[index + 1].field5 === '0') {
        const startTime = new Date(feed.created_at);
        const endTime = new Date(feeds[index + 1].created_at);
        const duration = (endTime - startTime) / (1000 * 60 * 60); // in hours
        acc[hour].onTime += duration;
      }

      return acc;
    }, {});

    const onTimeArray = Object.values(onTimePerHour);
    setHourlyOnTime(onTimeArray);
  };

  const filterDataByDay = (feeds, day) => {
    if (day === 'all') return feeds;

    const selectedDate = new Date(day);
    return feeds.filter(feed => {
      const feedDate = new Date(feed.created_at);
      return feedDate.toDateString() === selectedDate.toDateString();
    });
  };

  const filterDataByRange = (feeds, range) => {
    const endDate = new Date();
    const startDate = new Date();
    startDate.setDate(endDate.getDate() - range + 1);

    return feeds.filter(feed => {
      const feedDate = new Date(feed.created_at);
      return feedDate >= startDate && feedDate <= endDate;
    });
  };

  const aggregateTwoHourlyData = (feeds) => {
    const twoHourlyData = [];
    let currentStartHour = null;
    let aggregatedData = { fuelLevel: 0, batteryVoltage: 0, count: 0, created_at: null ,fuelpercentage: 0};

    feeds.forEach(feed => {
      const feedDate = new Date(feed.created_at);
      const feedHour = Math.floor(feedDate.getHours() / 2) * 2; // Round to the nearest even hour

      if (currentStartHour === null || feedHour !== currentStartHour) {
        if (aggregatedData.count > 0) {
          aggregatedData.fuelLevel /= aggregatedData.count;
          aggregatedData.batteryVoltage /= aggregatedData.count;
          twoHourlyData.push(aggregatedData);
        }
        currentStartHour = feedHour;
        aggregatedData = {
          fuelLevel: parseFloat(feed.field3),
          batteryVoltage: parseFloat(feed.field4),
          count: 1,
          created_at: new Date(feedDate.setHours(feedHour, 0, 0, 0)),
        };
      } else {
        aggregatedData.fuelLevel += parseFloat(feed.field3);
        aggregatedData.batteryVoltage += parseFloat(feed.field4);
        aggregatedData.count++;
      }
    });

    if (aggregatedData.count > 0) {
      aggregatedData.fuelLevel /= aggregatedData.count;
      aggregatedData.batteryVoltage /= aggregatedData.count;
      twoHourlyData.push(aggregatedData);
    }

    return twoHourlyData;
  };

  const aggregateDailyData = (feeds) => {
    const dailyData = [];
    let currentDay = null;
    let aggregatedData = { fuelLevel: 0, batteryVoltage: 0, count: 0, created_at: null , fuelpercentage: 0};

    feeds.forEach(feed => {
      const feedDate = new Date(feed.created_at);
      const feedDay = feedDate.toDateString();

      if (currentDay === null || feedDay !== currentDay) {
        if (aggregatedData.count > 0) {
          aggregatedData.fuelLevel /= aggregatedData.count;
          aggregatedData.batteryVoltage /= aggregatedData.count;
          dailyData.push(aggregatedData);
        }
        currentDay = feedDay;
        aggregatedData = {
          fuelLevel: parseFloat(feed.field3),
          batteryVoltage: parseFloat(feed.field4),
          count: 1,
          created_at: new Date(feedDate.setHours(0, 0, 0, 0)),
        };
      } else {
        aggregatedData.fuelLevel += parseFloat(feed.field3);
        aggregatedData.batteryVoltage += parseFloat(feed.field4);
        aggregatedData.count++;
      }
    });

    if (aggregatedData.count > 0) {
      aggregatedData.fuelLevel /= aggregatedData.count;
      aggregatedData.batteryVoltage /= aggregatedData.count;
      dailyData.push(aggregatedData);
    }

    return dailyData;
  };

  const daysOptions = () => {
    const days = [];
    for (let i = 0; i < 7; i++) {
      const date = new Date();
      date.setDate(date.getDate() - i);
      days.push(date.toISOString().split('T')[0]);
    }
    return days;
  };

  const rangeOptions = [2, 3, 4, 5, 6, 7];

  const filteredFeeds = filterDataByDay(machineData ? machineData.feeds : [], selectedDay);
  const rangeFilteredFeeds = filterDataByRange(machineData ? machineData.feeds : [], selectedRange);
  const displayedFeeds = selectedDay === 'all' ? aggregateDailyData(rangeFilteredFeeds) : aggregateTwoHourlyData(filteredFeeds);
  const { totalDuration, totalFuelConsumed } = calculateDurationAndFuel(rangeFilteredFeeds);

  return (
    <>
      <div className="col-12">
        <div className="card recent-sales overflow-auto">
          <div className="card-body">
            <h5 className="card-title">
              Genset Status Of {machineId} <span style={{ fontSize: '0.8rem', color: '#888' }}>| {selectedDay === 'all' ? `Last ${selectedRange} Days` : selectedDay}</span>
            </h5>
            
            <GensetInfo machineData={machineData} />

            <div style={{ marginBottom: '20px', display: 'flex', justifyContent: 'space-between' }}>
              <button onClick={() => setShowLineChart(!showLineChart)} style={{ padding: '10px 20px', backgroundColor: '#4CAF50', color: 'white', border: 'none', borderRadius: '5px', cursor: 'pointer' }}>
                {showLineChart ? 'Hide Line Chart' : 'Show Line Chart'}
              </button>
              <button onClick={() => setShowPieChart(!showPieChart)} style={{ padding: '10px 20px', backgroundColor: '#4CAF50', color: 'white', border: 'none', borderRadius: '5px', cursor: 'pointer' }}>
                {showPieChart ? 'Hide Pie Chart' : 'Show Pie Chart'}
              </button>
              <button onClick={() => setShowBarChart(!showBarChart)} style={{ padding: '10px 20px', backgroundColor: '#4CAF50', color: 'white', border: 'none', borderRadius: '5px', cursor: 'pointer' }}>
                {showBarChart ? 'Hide Bar Chart' : 'Show Bar Chart'}
              </button>
            </div>

            <div style={{ marginBottom: '20px', padding: '15px', backgroundColor: '#e9f7ef', borderRadius: '5px', border: '1px solid #ccc' }}>
              <h3 style={{ marginBottom: '10px' }}>Genset Operation Summary</h3>
              <p>Total Duration: {totalDuration.toFixed(2)} hours</p>
              <p>Total Fuel Consumed: {totalFuelConsumed.toFixed(2)} liters</p>
            </div>

            <div style={{ marginBottom: '20px' }}>
              <label htmlFor="day-select">Select Day:</label>
              <select id="day-select" value={selectedDay} onChange={(e) => setSelectedDay(e.target.value)} style={{ marginLeft: '10px' }}>
                <option value="all">All</option>
                {daysOptions().map(day => (
                  <option key={day} value={day}>{day}</option>
                ))}
              </select>

              <label htmlFor="range-select" style={{ marginLeft: '20px' }}>Select Range (Days):</label>
              <select id="range-select" value={selectedRange} onChange={(e) => setSelectedRange(e.target.value)} style={{ marginLeft: '10px' }}>
                {rangeOptions.map(range => (
                  <option key={range} value={range}>Last {range} Days</option>
                ))}
              </select>
            </div>

            <GensetCharts
              showLineChart={showLineChart}
              showPieChart={showPieChart}
              showBarChart={showBarChart}
              displayedFeeds={displayedFeeds}
              totalDuration={totalDuration}
              totalFuelConsumed={totalFuelConsumed}
              dailyOnTime={dailyOnTime}
              hourlyOnTime={hourlyOnTime}
              selectedDay={selectedDay}
            />

            <GensetTable displayedFeeds={displayedFeeds} />
          </div>
        </div>
      </div>
    </>
  );
}

export default GensetStatus;


// import React, { useState, useEffect } from 'react';
// import axios from 'axios';
// import { API_URL } from '../constants/Url';
// import GensetInfo from './GensetInfo';
// import GensetCharts from './GensetCharts';
// import GensetTable from './GensetTable';

// function GensetStatus({ machineId }) {
//   const [machineData, setMachineData] = useState(null);
//   const [selectedDay, setSelectedDay] = useState('all');
//   const [showLineChart, setShowLineChart] = useState(true);
//   const [showPieChart, setShowPieChart] = useState(true);
//   const [showBarChart, setShowBarChart] = useState(true);
//   const [dailyOnTime, setDailyOnTime] = useState([]);
//   const [hourlyOnTime, setHourlyOnTime] = useState([]);

//   useEffect(() => {
//     const fetchMachineData = async () => {
//       try {
//         const response = await axios.get(`${API_URL}/feeds/fetch-last-feeds?id=${machineId}&results=1000`);
//         setMachineData(response.data);
//         calculateDailyOnTime(response.data.feeds.reverse()); // Reverse the order of feeds
//       } catch (error) {
//         console.error('Error fetching machine data:', error);
//       }
//     };

//     fetchMachineData();
//   }, [machineId]);

//   useEffect(() => {
//     if (machineData) {
//       const filteredFeeds = filterDataByDay(machineData.feeds, selectedDay);
//       calculateDailyOnTime(filteredFeeds);
//       calculateHourlyOnTime(filteredFeeds);
//     }
//   }, [selectedDay, machineData]);

//   const calculateDurationAndFuel = (feeds) => {
//     if (!feeds || feeds.length === 0) return { totalDuration: 0, totalFuelConsumed: 0 };

//     let totalDuration = 0;
//     let totalFuelConsumed = 0;

//     for (let i = 0; i < feeds.length - 1; i++) {
//       if (feeds[i].field5 === '1' && feeds[i + 1].field5 === '0') {
//         const startTime = new Date(feeds[i].created_at);
//         const endTime = new Date(feeds[i + 1].created_at);
//         const duration = (endTime - startTime) / (1000 * 60 * 60); // in hours
//         const fuelConsumed = feeds[i].field3 - feeds[i + 1].field3; // assuming field3 is fuel level

//         totalDuration += duration;
//         totalFuelConsumed += fuelConsumed;
//       }
//     }

//     return { totalDuration, totalFuelConsumed };
//   };

//   const calculateDailyOnTime = (feeds) => {
//     if (!feeds || feeds.length === 0) return [];

//     const onTimePerDay = feeds.reduce((acc, feed, index) => {
//       const date = new Date(feed.created_at).toISOString().split('T')[0];
//       if (!acc[date]) {
//         acc[date] = { date, onTime: 0 };
//       }

//       if (feed.field5 === '1' && feeds[index + 1] && feeds[index + 1].field5 === '0') {
//         const startTime = new Date(feed.created_at);
//         const endTime = new Date(feeds[index + 1].created_at);
//         const duration = (endTime - startTime) / (1000 * 60 * 60); // in hours
//         acc[date].onTime += duration;
//       }

//       return acc;
//     }, {});

//     const onTimeArray = Object.values(onTimePerDay);
//     setDailyOnTime(onTimeArray);
//   };

//   const calculateHourlyOnTime = (feeds) => {
//     if (!feeds || feeds.length === 0 || selectedDay === 'all') return [];

//     const onTimePerHour = feeds.reduce((acc, feed, index) => {
//       const date = new Date(feed.created_at).toISOString().split('T')[0];
//       if (date !== selectedDay) return acc;

//       const hour = new Date(feed.created_at).getHours();
//       if (!acc[hour]) {
//         acc[hour] = { hour, onTime: 0 };
//       }

//       if (feed.field5 === '1' && feeds[index + 1] && feeds[index + 1].field5 === '0') {
//         const startTime = new Date(feed.created_at);
//         const endTime = new Date(feeds[index + 1].created_at);
//         const duration = (endTime - startTime) / (1000 * 60 * 60); // in hours
//         acc[hour].onTime += duration;
//       }

//       return acc;
//     }, {});

//     const onTimeArray = Object.values(onTimePerHour);
//     setHourlyOnTime(onTimeArray);
//   };

//   const filterDataByDay = (feeds, day) => {
//     if (day === 'all') return feeds;

//     const selectedDate = new Date(day);
//     return feeds.filter(feed => {
//       const feedDate = new Date(feed.created_at);
//       return feedDate.toDateString() === selectedDate.toDateString();
//     });
//   };

//   const aggregateTwoHourlyData = (feeds) => {
//     const twoHourlyData = [];
//     let currentStartHour = null;
//     let aggregatedData = { fuelLevel: 0, batteryVoltage: 0, count: 0, created_at: null ,fuelpercentage: 0};

//     feeds.forEach(feed => {
//       const feedDate = new Date(feed.created_at);
//       const feedHour = Math.floor(feedDate.getHours() / 2) * 2; // Round to the nearest even hour

//       if (currentStartHour === null || feedHour !== currentStartHour) {
//         if (aggregatedData.count > 0) {
//           aggregatedData.fuelLevel /= aggregatedData.count;
//           aggregatedData.batteryVoltage /= aggregatedData.count;
//           twoHourlyData.push(aggregatedData);
//         }
//         currentStartHour = feedHour;
//         aggregatedData = {
//           fuelLevel: parseFloat(feed.field3),
//           batteryVoltage: parseFloat(feed.field4),
//           count: 1,
//           created_at: new Date(feedDate.setHours(feedHour, 0, 0, 0)),
//         };
//       } else {
//         aggregatedData.fuelLevel += parseFloat(feed.field3);
//         aggregatedData.batteryVoltage += parseFloat(feed.field4);
//         aggregatedData.count++;
//       }
//     });

//     if (aggregatedData.count > 0) {
//       aggregatedData.fuelLevel /= aggregatedData.count;
//       aggregatedData.batteryVoltage /= aggregatedData.count;
//       twoHourlyData.push(aggregatedData);
//     }

//     return twoHourlyData;
//   };

//   const aggregateDailyData = (feeds) => {
//     const dailyData = [];
//     let currentDay = null;
//     let aggregatedData = { fuelLevel: 0, batteryVoltage: 0, count: 0, created_at: null , fuelpercentage: 0};

//     feeds.forEach(feed => {
//       const feedDate = new Date(feed.created_at);
//       const feedDay = feedDate.toDateString();

//       if (currentDay === null || feedDay !== currentDay) {
//         if (aggregatedData.count > 0) {
//           aggregatedData.fuelLevel /= aggregatedData.count;
//           aggregatedData.batteryVoltage /= aggregatedData.count;
//           dailyData.push(aggregatedData);
//         }
//         currentDay = feedDay;
//         aggregatedData = {
//           fuelLevel: parseFloat(feed.field3),
//           batteryVoltage: parseFloat(feed.field4),
//           count: 1,
//           created_at: new Date(feedDate.setHours(0, 0, 0, 0)),
//         };
//       } else {
//         aggregatedData.fuelLevel += parseFloat(feed.field3);
//         aggregatedData.batteryVoltage += parseFloat(feed.field4);
//         aggregatedData.count++;
//       }
//     });

//     if (aggregatedData.count > 0) {
//       aggregatedData.fuelLevel /= aggregatedData.count;
//       aggregatedData.batteryVoltage /= aggregatedData.count;
//       dailyData.push(aggregatedData);
//     }

//     return dailyData;
//   };

//   const daysOptions = () => {
//     const days = [];
//     for (let i = 0; i < 7; i++) {
//       const date = new Date();
//       date.setDate(date.getDate() - i);
//       days.push(date.toISOString().split('T')[0]);
//     }
//     return days;
//   };

//   const filteredFeeds = filterDataByDay(machineData ? machineData.feeds : [], selectedDay);
//   const displayedFeeds = selectedDay === 'all' ? aggregateDailyData(filteredFeeds) : aggregateTwoHourlyData(filteredFeeds);
//   const { totalDuration, totalFuelConsumed } = calculateDurationAndFuel(filteredFeeds);

//   return (
//     <>
//       <div className="col-12">
//         <div className="card recent-sales overflow-auto">
//           <div className="card-body">
//             <h5 className="card-title">
//               Genset Status Of {machineId} <span style={{ fontSize: '0.8rem', color: '#888' }}>| {selectedDay === 'all' ? 'Last 7 Days' : selectedDay}</span>
//             </h5>
            
//             <GensetInfo machineData={machineData} />

//             <div style={{ marginBottom: '20px', display: 'flex', justifyContent: 'space-between' }}>
//               <button onClick={() => setShowLineChart(!showLineChart)} style={{ padding: '10px 20px', backgroundColor: '#4CAF50', color: 'white', border: 'none', borderRadius: '5px', cursor: 'pointer' }}>
//                 {showLineChart ? 'Hide Line Chart' : 'Show Line Chart'}
//               </button>
//               <button onClick={() => setShowPieChart(!showPieChart)} style={{ padding: '10px 20px', backgroundColor: '#4CAF50', color: 'white', border: 'none', borderRadius: '5px', cursor: 'pointer' }}>
//                 {showPieChart ? 'Hide Pie Chart' : 'Show Pie Chart'}
//               </button>
//               <button onClick={() => setShowBarChart(!showBarChart)} style={{ padding: '10px 20px', backgroundColor: '#4CAF50', color: 'white', border: 'none', borderRadius: '5px', cursor: 'pointer' }}>
//                 {showBarChart ? 'Hide Bar Chart' : 'Show Bar Chart'}
//               </button>
//             </div>

//             <div style={{ marginBottom: '20px', padding: '15px', backgroundColor: '#e9f7ef', borderRadius: '5px', border: '1px solid #ccc' }}>
//               <h3 style={{ marginBottom: '10px' }}>Genset Operation Summary</h3>
//               <p>Total Duration: {totalDuration.toFixed(2)} hours</p>
//               <p>Total Fuel Consumed: {totalFuelConsumed.toFixed(2)} liters</p>
//             </div>

//             <div style={{ marginBottom: '20px' }}>
//               <label htmlFor="day-select">Select Day:</label>
//               <select id="day-select" value={selectedDay} onChange={(e) => setSelectedDay(e.target.value)} style={{ marginLeft: '10px' }}>
//                 <option value="all">All</option>
//                 {daysOptions().map(day => (
//                   <option key={day} value={day}>{day}</option>
//                 ))}
//               </select>
//             </div>

//             <GensetCharts
//               showLineChart={showLineChart}
//               showPieChart={showPieChart}
//               showBarChart={showBarChart}
//               displayedFeeds={displayedFeeds}
//               totalDuration={totalDuration}
//               totalFuelConsumed={totalFuelConsumed}
//               dailyOnTime={dailyOnTime}
//               hourlyOnTime={hourlyOnTime}
//               selectedDay={selectedDay}
//             />

//             <GensetTable displayedFeeds={displayedFeeds} />
//           </div>
//         </div>
//       </div>
//     </>
//   );
// }

// export default GensetStatus;








