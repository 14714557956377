import React, { useEffect, useState } from 'react';
import axios from 'axios';
import { useGlobalState } from '../constants/GlobalStateProvider';
import { FaBatteryFull, FaBatteryHalf, FaBatteryQuarter, FaBatteryThreeQuarters, FaBatteryEmpty } from 'react-icons/fa';
import { API_URL } from '../constants/Url';
import GensetStatus from './GensetStatus'; // Import GensetStatus component

const CACHE_TIMEOUT = 30 * 1000; // 30 seconds

const AnalyticsTable = () => {
  const [machinesData, setMachinesData] = useState([]);
  const [channelInfo, setChannelInfo] = useState([]);
  const [machineIds, setMachineIds] = useState([]);
  const [fuelPercentages, setFuelPercentages] = useState({});
  const [fuelCapacities, setFuelCapacities] = useState({});
  const [searchTerm, setSearchTerm] = useState('');
  const [currentPage, setCurrentPage] = useState(1);
  const [rowsPerPage] = useState(5);
  const [selectedMachineId, setSelectedMachineId] = useState(null); // Add state to track selected machine ID

  const { getGlobal } = useGlobalState();
  const globalState = getGlobal();

  useEffect(() => {
    if (globalState) {
      fetchMachineInfo(globalState);
    }
  }, [globalState]);

  useEffect(() => {
    if (machineIds.length > 0) {
      fetchData(machineIds);
    }
  }, [machineIds]);

  const fetchMachineInfo = async (globalState) => {
    try {
      const response = await axios.get(`${API_URL}/machines/user/${globalState}`);
      const data = response.data;

      if (data.success) {
        setMachineIds(data.machine_ids);

        const capacities = data.fuel_capacities.reduce((acc, { machine_id, fuel_capacity }) => {
          acc[machine_id] = fuel_capacity;
          return acc;
        }, {});
        setFuelCapacities(capacities);
      }
    } catch (error) {
      console.error('Error fetching user info:', error);
    }
  };

  const fetchData = async (machineIds) => {
    const cachedData = JSON.parse(localStorage.getItem('machineData')) || {};
    const lastFetchTime = cachedData.timestamp || 0;

    if (Date.now() - lastFetchTime < CACHE_TIMEOUT) {
      setChannelInfo(cachedData.channels);
      setMachinesData(cachedData.feeds);
      return;
    }

    let timeoutReached = false;
    const timeout = setTimeout(() => {
      timeoutReached = true;
      setChannelInfo(cachedData.channels);
      setMachinesData(cachedData.feeds);
    }, CACHE_TIMEOUT);

    try {
      const apiPromises = machineIds.map((machineId) => {
        return axios.get(`${API_URL}/feeds/fetch-last-feeds?id=${machineId}&results=1`);
      });

      const results = await axios.all(apiPromises);
      clearTimeout(timeout);

      if (!timeoutReached) {
        const dataArray = results.map((result) => result.data);
        const channels = dataArray.map((data) => data.channel);
        const feeds = dataArray.map((data) => data.feeds[0] || {});

        const newData = {
          timestamp: Date.now(),
          channels,
          feeds,
        };

        localStorage.setItem('machineData', JSON.stringify(newData));

        setChannelInfo(channels);
        setMachinesData(feeds);
      }
    } catch (error) {
      console.error('Error fetching data:', error);
    }
  };

  const renderTableData = () => {
    const indexOfLastRow = currentPage * rowsPerPage;
    const indexOfFirstRow = indexOfLastRow - rowsPerPage;

    const filteredMachines = machinesData.filter((machine, index) => {
      const { id, name, zone, area } = channelInfo[index] || {};
      const fullName = `${name}`.toLowerCase();
      return (
        searchTerm === '' ||
        fullName.includes(searchTerm.toLowerCase()) ||
        id?.toString().includes(searchTerm.toLowerCase()) ||
        zone?.toLowerCase().includes(searchTerm.toLowerCase()) ||
        area?.toLowerCase().includes(searchTerm.toLowerCase())
      );
    });

    const handleRowClick = (machineId) => {
      setSelectedMachineId(machineId);
    };

    const currentRows = filteredMachines.slice(indexOfFirstRow, indexOfLastRow);

    return currentRows.map((machine, index) => {
      const fuelCapacity = fuelCapacities[channelInfo[index]?.id];
      const fuelLevel = parseFloat(machine.field1);
      const fuelPercentage = parseFloat(machine.field3);
      const batteryVoltage = parseFloat(machine.field4);

      let fuelIcon;
      let fuelColor;
      if (fuelPercentage === null) {
        fuelIcon = <FaBatteryEmpty />;
        fuelColor = 'gray';
      } else if (fuelPercentage > 75) {
        fuelIcon = <FaBatteryFull />;
        fuelColor = 'green';
      } else if (fuelPercentage > 50) {
        fuelIcon = <FaBatteryThreeQuarters />;
        fuelColor = 'limegreen';
      } else if (fuelPercentage > 25) {
        fuelIcon = <FaBatteryHalf />;
        fuelColor = 'orange';
      } else {
        fuelIcon = <FaBatteryQuarter />;
        fuelColor = 'red';
      }

      return (
        <tr key={index} onClick={() => handleRowClick(channelInfo[index]?.id)} style={{ cursor: 'pointer' }}>
          <td>{channelInfo[index]?.id || 'N/A'}</td>
          <td>{channelInfo[index]?.name || 'N/A'}</td>
          <td>{machine.created_at ? new Date(machine.created_at).toLocaleString() : 'N/A'}</td>
          {/* <td>{machine.field1 || 'N/A'}</td>
          <td>{machine.field2 || 'N/A'}</td> */}
          <td>
            {fuelPercentage !== null ? (
              <span className="battery-icon" style={{ color: fuelColor }}>
                {fuelIcon} <b><span className="battery-text">{fuelPercentage.toFixed(2)}%</span></b>
              </span>
            ) : (
              'NA'
            )}
          </td>
          <td>{batteryVoltage.toFixed(2) || 'N/A'}</td>
          <td>
            <button className={`btn ${machine.field5 === '1' ? 'btn-success' : 'btn-danger'}`}>
              {machine.field5 === '1' ? 'On' : 'Off'}
            </button>
          </td>
          <td>
          <i className="bi bi-bar-chart-line" style={{ fontSize: '24px' }}></i>
          
          <i class="bi bi-pie-chart" style={{ fontSize: '24px' ,padding:'20px'}}></i>
          </td>
        </tr>
      );
    });
  };

  const nextPage = () => setCurrentPage((prevPage) => prevPage + 1);
  const prevPage = () => setCurrentPage((prevPage) => prevPage - 1);

  const totalPages = Math.ceil(machinesData.length / rowsPerPage);

  return (
    <div className="col-12">
      <div className="card recent-sales overflow-auto">
        <div className="card-body">
          <h5 className="card-title">Machines Analytics Table | Total Machines: {machineIds.length}</h5>
          {selectedMachineId ? (
            <GensetStatus machineId={selectedMachineId} />
          ) : (
            <div>
              <div className="search-bar">
                <input
                  type="text"
                  className="form-control"
                  placeholder="Search by ID or Machine Name"
                  value={searchTerm}
                  onChange={(event) => setSearchTerm(event.target.value)}
                />
              </div>
              <table className="table table-hover">  
              <thead className="table-primary">
                  <tr>
                    <th scope="col">Machine ID</th>
                    <th scope="col">Machine Name</th>
                    <th scope="col">Last Update</th>
                    {/* <th scope="col">Total Capacity</th>
                    <th scope="col">Current Capacity</th> */}
                    <th scope="col">Fuel Level</th>
                    <th scope="col">Battery</th>
                    <th scope="col">Genset</th>
                    <th scope="col">Analytics</th>
                  </tr>
                </thead>
                <tbody>{renderTableData()}</tbody>
              </table>
              {/* <div className="pagination">
                <button onClick={prevPage} disabled={currentPage === 1}>
                  Previous
                </button>
                <span>
                  Page {currentPage} of {totalPages}
                </span>
                <button onClick={nextPage} disabled={currentPage === totalPages}>
                  Next
                </button>
              </div> */}
                        <div className="d-flex justify-content-between align-items-center mt-3">
            <button onClick={prevPage} disabled={currentPage === 1} className="btn btn-outline-secondary">Previous</button>
            <span className="page-no">Page {currentPage} of {totalPages}</span>
            <button onClick={nextPage} disabled={currentPage === totalPages} className="btn btn-outline-secondary">Next</button>
          </div>
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default AnalyticsTable;


