// import React, { useState } from 'react';


// function NotificationsForm() {
//   const [isEnabled, setIsEnabled] = useState(false);

//   const toggleNotification = () => {
//     setIsEnabled(!isEnabled);
//     // You can add additional logic here, like sending a request to enable/disable notifications
//   };

//     return (
//         <div>
//             {/* Profile Edit Form */}
//             <form>
//                 <h1>Notifications Settings</h1>

//                 <div className="row mb-3">
//                 <div style={styles.container}>
//                     <label style={styles.switch}>
//                         <input
//                             type="checkbox"
//                             checked={isEnabled}
//                             onChange={toggleNotification}
//                         />
//                         <span style={styles.slider}></span>
//                     </label>
//                     <p style={styles.text}>
//                         Notifications {isEnabled ? 'Enabled' : 'Disabled'}
//                     </p>
//                 </div>
//                     {/* <label
//                         htmlFor="profileImage"
//                         className="col-md-4 col-lg-3 col-form-label"
//                       >
//                         Profile Image
//                       </label> */}
//                     <div className="col-md-8 col-lg-9">
//                         {/* <img src="assets/img/Profile/Profile-img.png" alt="Profile" /> */}
//                         <div className="pt-2">
//                             <a
//                                 href="#"
//                                 className="btn btn-primary btn-sm"
//                                 title="Upload new profile image"
//                             >
//                                 <i className="bi bi-upload" />
//                             </a>
//                             <a
//                                 href="#"
//                                 className="btn btn-danger btn-sm"
//                                 title="Remove my profile image"
//                             >
//                                 <i className="bi bi-trash" />
//                             </a>
//                         </div>
//                     </div>
//                 </div>
//                 <div className="row mb-3">
//                     <label
//                         htmlFor="fullName"
//                         className="col-md-4 col-lg-3 col-form-label"
//                     >
//                         Mobile No.
//                     </label>
//                     <div className="col-md-8 col-lg-9">
//                         <input
//                             name="fullName"
//                             type="text"
//                             className="form-control"
//                             id="fullName"
//                             defaultValue="User 1 name "
//                         />
//                     </div>
//                 </div>

//                 <div className="row mb-3">
//                     <label
//                         htmlFor="company"
//                         className="col-md-4 col-lg-3 col-form-label"
//                     >
//                         Alternate Mobile No.
//                     </label>
//                     <div className="col-md-8 col-lg-9">
//                         <input
//                             name="company"
//                             type="text"
//                             className="form-control"
//                             id="company"
//                             defaultValue="College "
//                         />
//                     </div>
//                 </div>

//                 <div className="row mb-3">
//                     <label
//                         htmlFor="Country"
//                         className="col-md-4 col-lg-3 col-form-label"
//                     >
//                         Email Id
//                     </label>
//                     <div className="col-md-8 col-lg-9">
//                         <input
//                             name="country"
//                             type="text"
//                             className="form-control"
//                             id="Country"
//                             defaultValue="India"
//                         />
//                     </div>
//                 </div>
//                 <div className="row mb-3">
//                     <label
//                         htmlFor="Address"
//                         className="col-md-4 col-lg-3 col-form-label"
//                     >
//                         Alternate Email Id
//                     </label>
//                     <div className="col-md-8 col-lg-9">
//                         <input
//                             name="address"
//                             type="text"
//                             className="form-control"
//                             id="Address"
//                             defaultValue="Katol Road Nagpur "
//                         />
//                     </div>
//                 </div>
//                 {/* <div className="row mb-3">
//                       <label
//                         htmlFor="Phone"
//                         className="col-md-4 col-lg-3 col-form-label"
//                       >
//                         Phone
//                       </label>
//                       <div className="col-md-8 col-lg-9">
//                         <input
//                           name="phone"
//                           type="text"
//                           className="form-control"
//                           id="Phone"
//                           defaultValue="(+91) 943997272"
//                         />
//                       </div>
//                     </div> */}
//                 {/* <div className="row mb-3">
//                       <label
//                         htmlFor="Email"
//                         className="col-md-4 col-lg-3 col-form-label"
//                       >
//                         Email
//                       </label>
//                       <div className="col-md-8 col-lg-9">
//                         <input
//                           name="email"
//                           type="email"
//                           className="form-control"
//                           id="Email"
//                           defaultValue="sigma123@gmail.com"
//                         />
//                       </div>
//                     </div> */}

//                 <div className="text-center">
//                     <button type="submit" className="btn btn-primary">
//                         Save Changes
//                     </button>
//                 </div>
//             </form>
//             {/* End Profile Edit Form */}
//         </div>
//     )
// }

// const styles = {
//     container: {
//       display: 'flex',
//       alignItems: 'center',
//     },
//     switch: {
//       position: 'relative',
//       display: 'inline-block',
//       width: '60px',
//       height: '34px',
//     },
//     slider: {
//       position: 'absolute',
//       cursor: 'pointer',
//       top: '0',
//       left: '0',
//       right: '0',
//       bottom: '0',
//       backgroundColor: '#ccc',
//       transition: '.4s',
//       borderRadius: '34px',
//     },
//     sliderBefore: {
//       position: 'absolute',
//       content: '""',
//       height: '26px',
//       width: '26px',
//       left: '4px',
//       bottom: '4px',
//       backgroundColor: 'white',
//       transition: '.4s',
//       borderRadius: '50%',
//     },
//     text: {
//       marginLeft: '10px',
//     }
//   };

// export default NotificationsForm

import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { API_URL } from '../constants/Url';
import { useGlobalState } from '../constants/GlobalStateProvider';

function NotificationsForm() {
    const [isEnabled, setIsEnabled] = useState(false);
    const [phone1, setMobile1] = useState('');
    const [phone2, setMobile2] = useState('');
    const [email1, setEmail1] = useState('');
    const [email2, setEmail2] = useState('');
    const { getGlobal } = useGlobalState();
    const globalState = getGlobal();
    const [machineIds, setMachineIds] = useState([]);
    const [machine_count, setMachineCount] = useState();

    useEffect(() => {
        if (globalState) {
            fetchMachineInfo(globalState);
        }
    });

    const fetchMachineInfo = async (globalState) => {
        try {
            const response = await axios.get(`${API_URL}/machines/user/${globalState}`);
            const data = response.data;

            if (data.success) {
                setMachineIds(data.machine_ids);
                setMachineCount(data.machine_count);
            }
        } catch (error) {
            console.error('Error fetching user info:', error);
        }
    };



    const toggleNotification = () => {
        setIsEnabled(!isEnabled);
        // Additional logic can be added here.
    };

    const handleSubmit = async (event) => {
        event.preventDefault();

        const dataToSubmit = {
            user_id: globalState, // Assuming globalState is set with user_id
            machineIds: machineIds,
            phone1,
            phone2,
            email1,
            email2,
            isEnabled,
        };





        try {
            const response = await axios.post(`http://localhost:5000/notification/createChannel`, dataToSubmit);
            if (response) {
                alert('Notification channel created successfully');
            } else {
                alert('Failed to notify');
            }
        } catch (error) {
            alert('Failed to notify');
            console.error('Error updating notify:', error);
        }
    };

    return (
        <div>
            {/* Profile Edit Form */}
            <form onSubmit={handleSubmit}>
                <h1>Notifications Settings - {machineIds}</h1>

                <div className="row mb-3">
                    <div style={styles.container}>
                        <label style={styles.switch}>
                            <input
                                type="checkbox"
                                checked={isEnabled}
                                onChange={toggleNotification}
                            />
                            <span style={styles.slider}></span>
                        </label>
                        <p style={styles.text}>
                            Notifications {isEnabled ? 'Enabled' : 'Disabled'}
                        </p>
                    </div>
                </div>

                <div className="row mb-3">
                    <label htmlFor="mobile1" className="col-md-4 col-lg-3 col-form-label">
                        Mobile No.
                    </label>
                    <div className="col-md-8 col-lg-9">
                        <input
                            name="phone1"
                            onChange={(e) => setMobile1(e.target.value)}
                            type="text"
                            className="form-control"
                            id="phone1"
                        />
                    </div>
                </div>

                <div className="row mb-3">
                    <label htmlFor="mobile2" className="col-md-4 col-lg-3 col-form-label">
                        Alternate Mobile No.
                    </label>
                    <div className="col-md-8 col-lg-9">
                        <input
                            name="phone2"
                            onChange={(e) => setMobile2(e.target.value)}
                            type="text"
                            className="form-control"
                            id="phone2"
                        />
                    </div>
                </div>

                <div className="row mb-3">
                    <label htmlFor="email1" className="col-md-4 col-lg-3 col-form-label">
                        Email Id
                    </label>
                    <div className="col-md-8 col-lg-9">
                        <input
                            name="email1"
                            onChange={(e) => setEmail1(e.target.value)}
                            type="text"
                            className="form-control"
                            id="email1"
                        />
                    </div>
                </div>

                <div className="row mb-3">
                    <label htmlFor="email2" className="col-md-4 col-lg-3 col-form-label">
                        Alternate Email Id
                    </label>
                    <div className="col-md-8 col-lg-9">
                        <input
                            name="email2"
                            onChange={(e) => setEmail2(e.target.value)}
                            type="text"
                            className="form-control"
                            id="email2"
                        />
                    </div>
                </div>

                <div className="text-center">
                    <button type="submit" className="btn btn-primary" onClick={handleSubmit}>
                        Save Changes
                    </button>
                </div>
            </form>
            {/* End Profile Edit Form */}
        </div>
    );
}

const styles = {
    container: {
        display: 'flex',
        alignItems: 'center',
    },
    switch: {
        position: 'relative',
        display: 'inline-block',
        width: '0px',
        height: '0px',
    },
    slider: {
        position: 'absolute',
        cursor: 'pointer',
        top: '0',
        left: '0',
        right: '0',
        bottom: '0',
        backgroundColor: '#ccc',
        transition: '.4s',
        borderRadius: '34px',
    },
    sliderBefore: {
        position: 'absolute',
        content: '""',
        height: '26px',
        width: '26px',
        left: '4px',
        bottom: '4px',
        backgroundColor: 'white',
        transition: '.4s',
        borderRadius: '50%',
    },
    text: {
        marginLeft: '10px',
    }
};

export default NotificationsForm;
