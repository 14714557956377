
import React, { useState, useEffect } from 'react';
import Header from './components/Header/Header';
import Sidebar from './components/Sidebar/Sidebar';
import Dashboard from './components/Maindash/Dashboard';
import UserLogin from './components/UserLogin/UserLogin';

function App() {
  
  return (
    <div className="app-container">
      <UserLogin/>
    </div>
  );
}

export default App;
