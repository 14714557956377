import React, { useState, useEffect, useRef } from 'react';
import Header from '../Header/Header';
import Sidebar from '../Sidebar/Sidebar';
import { useLocation } from 'react-router-dom';
import axios from 'axios';
import { useGlobalState } from '../constants/GlobalStateProvider';
import { API_URL } from '../constants/Url';
import MapComponent from './MapComponent';
import LocationMachineTable from './LocationMachineTable';
import styled from 'styled-components';
import { useNavigate } from 'react-router-dom';
import LocationButton from '../location1/LocationButton';

const SearchBar = styled.div`
  display: flex;
  margin-bottom: 16px;
  transition: max-height 0.3s ease-in-out;
  overflow: hidden;
  max-height: ${props => (props.expanded ? '100px' : '0')};
`;

const Button = styled.button`
  margin: 8px;
  padding: 10px 20px;
  border: none;
  border-radius: 4px;
  background-color: #007bff;
  color: white;
  cursor: pointer;
  transition: background-color 0.3s ease, transform 0.3s ease;

  &:hover {
    background-color: #0056b3;
    transform: translateY(-2px);
  }
`;

const ButtonGroup = styled.div`
  display: flex;
  justify-content: center;
  margin-bottom: 16px;
`;

function IndexLocation() {
  const [isSidebarOpen, setIsSidebarOpen] = useState(true);
  const [searchTerm, setSearchTerm] = useState('');
  const [mapLocation, setMapLocation] = useState({ latitude: 37.7749, longitude: -122.4194 });
  const [isSearchBarExpanded, setIsSearchBarExpanded] = useState(false);
  const navigate = useNavigate();
  const mapSectionRef = useRef(null);
  const tableSectionRef = useRef(null);
  const setLocationRef = useRef(null);

  useEffect(() => {
    const handleViewportChange = () => {
      const isMobile = window.matchMedia('(max-width: 991.98px)').matches;
      setIsSidebarOpen(!isMobile);
    };

    handleViewportChange();

    window.addEventListener('resize', handleViewportChange);
    return () => {
      window.removeEventListener('resize', handleViewportChange);
    };
  }, []);

  const toggleSidebar = () => {
    setIsSidebarOpen(!isSidebarOpen);
  };

  const [userInfo, setUserInfo] = useState(null);
  const location = useLocation();
  const { getGlobal } = useGlobalState();
  const globalState = getGlobal();

  useEffect(() => {
    if (globalState) {
      fetchUserInfo(globalState);
    }
  }, [location]);

  const fetchUserInfo = async (globalState) => {
    try {
      const response = await axios.get(`${API_URL}/customers/user/${globalState}`);
      const data = response.data;
      console.log('User Info:', data);

      if (data.success) {
        setUserInfo(data);
      }
    } catch (error) {
      console.error('Error fetching user info:', error);
    }
  };

  const handleSearchSubmit = async (event) => {
    event.preventDefault();
    try {
      const response = await axios.get('https://nominatim.openstreetmap.org/search', {
        params: {
          q: searchTerm,
          format: 'json',
        },
      });
      const data = response.data;

      if (data.length > 0) {
        const { lat, lon } = data[0];
        setMapLocation({ latitude: parseFloat(lat), longitude: parseFloat(lon) });
        mapSectionRef.current.scrollIntoView({ behavior: 'smooth' });
      } else {
        alert('Location not found');
      }
    } catch (error) {
      console.error('Error fetching location data:', error);
      alert('Error fetching location data');
    }
  };

  const handleZoneOrAreaClick = async (type, value) => {
    try {
      const response = await axios.get('https://nominatim.openstreetmap.org/search', {
        params: {
          q: value,
          format: 'json',
        },
      });
      const data = response.data;

      if (data.length > 0) {
        const { lat, lon } = data[0];
        setMapLocation({ latitude: parseFloat(lat), longitude: parseFloat(lon) });
        mapSectionRef.current.scrollIntoView({ behavior: 'smooth' });
      } else {
        alert(`${type} not found`);
      }
    } catch (error) {
      console.error(`Error fetching ${type} data:`, error);
      alert(`Error fetching ${type} data`);
    }
  };

  const handleFindMachinesClick = () => {
    tableSectionRef.current.scrollIntoView({ behavior: 'smooth' });
  };
  const handleSetLoction = () => {
    setLocationRef.current.scrollIntoView({ behavior: 'smooth' });
  };


  const toggleSearchBar = () => {
    setIsSearchBarExpanded(!isSearchBarExpanded);
  };

  return (
    <div className="app-container">
      <Header toggleSidebar={toggleSidebar} user_id={globalState} />
      <div className={`content ${isSidebarOpen && window.innerWidth > 991.98 ? 'content-shifted' : ''}`}>
        <Sidebar isOpen={isSidebarOpen} closeSidebar={toggleSidebar} user_id={userInfo?.id} />
        <section className="section dashboard">
          <div className="container-fluid">
            <div className="row mb-4">
              <div className="col-12 mb-4" ref={mapSectionRef}>
                <br />
                <br />
                <br />
                <br />
              
                <div className="card">
                  <div className="card-body">
                    {/* <h5 className="card-title">Search Location</h5> */}
                    <br/>
                    <ButtonGroup>
                      <Button onClick={toggleSearchBar}>Search Location</Button>
                      <Button onClick={handleFindMachinesClick}>Find Machines</Button>
                      <Button onClick={handleSetLoction}>Set Location</Button>
                    </ButtonGroup>
                    <SearchBar expanded={isSearchBarExpanded}>
                      <form onSubmit={handleSearchSubmit} className="mb-4">
                        <div className="input-group">
                          <input
                            type="text"
                            className="form-control"
                            placeholder="Enter location name"
                            value={searchTerm}
                            onChange={(e) => setSearchTerm(e.target.value)}
                          />
                          <Button type="submit">Search</Button>
                        </div>
                      </form>
                    </SearchBar>
                    <div className="map-container">
                      <MapComponent latitude={mapLocation.latitude} longitude={mapLocation.longitude} locationName={searchTerm} />
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-12 mb-4" ref={tableSectionRef}>
                
                <div className="card-body">
                  <LocationMachineTable onZoneOrAreaClick={handleZoneOrAreaClick} />
                </div>
                <div className="card-body" ref={setLocationRef}>
                  <LocationButton/>
                </div>
            </div>
            </div>
          </div>
        </section>
      </div>
    </div>
  );
}

export default IndexLocation;
