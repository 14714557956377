// import React, { useEffect, useState } from 'react';
// import ApexCharts from 'apexcharts';

// const LineChartOverall = () => {
//   const [filter, setFilter] = useState('Today');

//   useEffect(() => {
//     const data = {
//       series: [
//         {
//           name: 'Machine 1',
//           data: [31, 40, 28, 51, 42, 82, 56],
//         },
//         {
//           name: 'Machine 2',
//           data: [11, 32, 45, 32, 34, 52, 41]
//         },
//         {
//           name: 'Machine 3',
//           data: [15, 11, 32, 18, 9, 24, 11]
//         }
//       ],
//       chart: {
//         type: 'area',
//         toolbar: {
//           show: false
//         },
//       },
//       markers: {
//         size: 4
//       },
//       colors: ['#4154f1', '#2eca6a', '#ff771d'],
//       fill: {
//         type: "gradient",
//         gradient: {
//           shadeIntensity: 1,
//           opacityFrom: 0.3,
//           opacityTo: 0.4,
//           stops: [0, 90, 100]
//         }
//       },
//       dataLabels: {
//         enabled: false
//       },
//       stroke: {
//         curve: 'smooth',
//         width: 2
//       },
//       xaxis: generateXAxisOptions(filter),
//       tooltip: {
//         x: {
//           format: 'dd/MM/yy HH:mm'
//         },
//       },
//       responsive: [
//         {
//           breakpoint: 480,
//           options: {
//             chart: {
//               width: '100%'
//             }
//           }
//         }
//       ]
//     };

//     const chart = new ApexCharts(document.querySelector("#lineChartOverall"), data);
//     chart.render();

//     return () => {
//       chart.destroy();
//     };
//   }, [filter]);

//   // Function to generate x-axis options based on filter
//   const generateXAxisOptions = (filter) => {
//     let categories, tickAmount;

//     if (filter === 'This Month') {
//       categories = ['Week 1', 'Week 2', 'Week 3', 'Week 4'];
//       tickAmount = 4;
//     } else if (filter === 'This Year') {
//       categories = ['January', 'February', 'March', 'April', 'May', 'June', 'July', 'August', 'September', 'October', 'November', 'December'];
//       tickAmount = 12;
//     } else {
//       categories = ['Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday', 'Sunday'];
//       tickAmount = 7;
//     }

//     return {
//       type: 'category',
//       categories: categories,
//       tickAmount: tickAmount,
//       labels: {
//         formatter: function (value, index) {
//           return categories[index] || value;
//         }
//       }
//     };
//   };

//   // Handler function for filter change
//   const handleFilterChange = (selectedFilter) => {
//     setFilter(selectedFilter);
//   };

//   return (
//     <div className="col-lg-8">
//       <div className="card">
//         <div className="filter">
//           <a className="icon" href="#" data-bs-toggle="dropdown"><i className="bi bi-three-dots"></i></a>
//           <ul className="dropdown-menu dropdown-menu-end dropdown-menu-arrow">
//             <li className="dropdown-header text-start">
//               <h6>Filter</h6>
//             </li>
//             <li><a className="dropdown-item" href="#" onClick={() => handleFilterChange('Today')}>Today</a></li>
//             <li><a className="dropdown-item" href="#" onClick={() => handleFilterChange('This Month')}>This Month</a></li>
//             <li><a className="dropdown-item" href="#" onClick={() => handleFilterChange('This Year')}>This Year</a></li>
//           </ul>
//         </div>
//         <div className="card-body">
//           <h5 className="card-title">Reports <span>/ {filter}</span></h5>
//           <div id="lineChartOverall" style={{ width: '100%' }}></div>
//         </div>
//       </div>
//     </div>
//   );
// };

// export default LineChartOverall;




import React, { useEffect, useState } from 'react';
import axios from 'axios';
import ApexCharts from 'apexcharts';
import { API_URL } from '../constants/Url';
import { useGlobalState } from '../constants/GlobalStateProvider';

const LineChartOverall = () => {
  const { getGlobal } = useGlobalState();
  const globalState = getGlobal();
  const [filter, setFilter] = useState('Today');
  const [chartData, setChartData] = useState({});
  const [machineIds, setMachineIds] = useState([]);

  useEffect(() => {
    if (globalState) {
      fetchMachineInfo(globalState);
    }
  }, [globalState]);

  useEffect(() => {
    if (machineIds.length > 0) {
      fetchData(machineIds);
    }
  }, [machineIds]);

  const fetchMachineInfo = async (globalState) => {
    try {
      const response = await axios.get(`${API_URL}/machines/user/${globalState}`);
      const data = response.data;

      if (data.success) {
        setMachineIds(data.machine_ids);
      }
    } catch (error) {
      console.error('Error fetching user info:', error);
    }
  };

  const fetchData = async (machineIds) => {
    try {
      const promises = machineIds.map(id => axios.get(`${API_URL}/feeds/fetch-last-feeds?id=${id}&results=1000`));
      const responses = await Promise.all(promises);

      const allFeeds = responses.map(response => response.data.feeds.reverse());
      const data = processChartData(allFeeds, machineIds);
      console.log('Processed Data:', data);
      setChartData(data);
    } catch (error) {
      console.error('Error fetching data:', error);
    }
  };

  useEffect(() => {
    if (Object.keys(chartData).length > 0) {
      const data = generateChartData(filter, chartData);
      console.log('Chart Data:', data);
      const options = getChartOptions(data, filter);
      const chart = new ApexCharts(document.querySelector("#lineChartOverall"), options);
      chart.render();

      return () => {
        chart.destroy();
      };
    }
  }, [filter, chartData]);

  const processChartData = (allFeeds, machineIds) => {
    const machines = {};

    allFeeds.forEach((feeds, index) => {
      const machineId = machineIds[index];
      machines[machineId] = feeds.map(feed => ({
        fuelpercentage: parseFloat(feed.field3),
        timestamp: new Date(feed.created_at)
      }));
    });

    return machines;
  };

  const generateChartData = (filter, machines) => {
    const filteredData = {};
  
    Object.keys(machines).forEach(machineId => {
      const machineData = machines[machineId];
      const filteredMachineData = filterDataByInterval(machineData, filter);
      filteredData[machineId] = filteredMachineData.map(data => data.fuelpercentage);
    });
  
    return filteredData;
  };

  const filterDataByInterval = (data, filter) => {
    const now = new Date();
    let startDate, intervalCount, intervalMs;
  
    if (filter === 'Today') {
      startDate = new Date(now.getFullYear(), now.getMonth(), now.getDate());
      intervalCount = 12;
      intervalMs = 24 * 60 * 60 * 1000 / intervalCount; // 2 hours
    } else if (filter === 'This Month') {
      startDate = new Date(now.getFullYear(), now.getMonth(), 1);
      intervalCount = 30; // Approximate number of days in a month
      intervalMs = 24 * 60 * 60 * 1000; // 1 day
    } else if (filter === 'This Year') {
      startDate = new Date(now.getFullYear(), 0, 1);
      intervalCount = 12; // 12 months
      intervalMs = 30 * 24 * 60 * 60 * 1000; // Approximately 1 month
    } else {
      return data; // Return all data if filter is not recognized
    }
  
    const filteredData = data.filter(item => item.timestamp >= startDate);
    const result = [];
  
    for (let i = 0; i < intervalCount; i++) {
      const intervalStart = new Date(startDate.getTime() + i * intervalMs);
      const intervalEnd = new Date(startDate.getTime() + (i + 1) * intervalMs);
  
      const intervalData = filteredData.filter(item =>
        item.timestamp >= intervalStart && item.timestamp < intervalEnd
      );
  
      if (intervalData.length > 0) {
        const avgFuelPercentage = intervalData.reduce((sum, item) => sum + item.fuelpercentage, 0) / intervalData.length;
        result.push({
          fuelpercentage: avgFuelPercentage,
          timestamp: intervalStart
        });
      }
    }
  
    return result;
  };

  const diffInUnits = (date1, date2, unit) => {
    const diffMs = Math.abs(date1 - date2);
    switch (unit) {
      case 'hours':
        return diffMs / (1000 * 60 * 60);
      case 'days':
        return diffMs / (1000 * 60 * 60 * 24);
      default:
        return diffMs;
    }
  };

  const getChartOptions = (data, filter) => {
    return {
      series: Object.keys(data).map(machineId => ({
        name: machineId,
        data: data[machineId]
      })),
      chart: {
        type: 'line',
        toolbar: {
          show: false
        },
      },
      markers: {
        size: 4
      },
      yaxis: {
        title: {
          text: 'Fuel Percentage'
        },
        labels: {
          formatter: function (value) {
            return value.toFixed(2) + '%'; // Display 2 decimal places and add % symbol
          }
        },
        min: 75, // Set a minimum value to focus on the relevant range
        max: 100, // Set a maximum value
        tickAmount: 5, // Adjust this to control the number of y-axis labels
      },
      colors: ['#4154f1', '#2eca6a', '#ff771d'],
      fill: {
        type: "gradient",
        gradient: {
          shadeIntensity: 1,
          opacityFrom: 0.3,
          opacityTo: 0.4,
          stops: [0, 90, 100]
        }
      },
      dataLabels: {
        enabled: false
      },
      stroke: {
        curve: 'smooth',
        width: 2
      },
      xaxis: generateXAxisOptions(filter),
      tooltip: {
        x: {
          format: 'dd/MM/yy HH:mm'
        },
      },
      responsive: [
        {
          breakpoint: 480,
          options: {
            chart: {
              width: '100%'
            }
          }
        }
      ]
    };
  };

  const generateXAxisOptions = (filter) => {
    let categories, tickAmount;

    if (filter === 'Today') {
      const categories = Array.from({ length: 12 }, (_, i) => {
        const hour = i * 2;
        return `${hour.toString().padStart(2, '0')}:00`;
      });
      return {
        type: 'category',
        categories: categories,
        tickAmount: 12,
        labels: {
          formatter: function (value, index) {
            return categories[index] || value;
          }
        }
      };
    }else if (filter === 'This Month') {
      categories = ['Week 1', 'Week 2', 'Week 3', 'Week 4'];
      tickAmount = 4;
    } else if (filter === 'This Year') {
      categories = ['January', 'February', 'March', 'April', 'May', 'June', 'July', 'August', 'September', 'October', 'November', 'December'];
      tickAmount = 12;
    }

    return {
      type: 'category',
      categories: categories,
      tickAmount: tickAmount,
      labels: {
        formatter: function (value, index) {
          return categories[index] || value;
        }
      }
    };
  };

  const handleFilterChange = (selectedFilter) => {
    setFilter(selectedFilter);
  };

  return (
    <div className="col-lg-8">
      <div className="card">
        <div className="filter">
          <a className="icon" href="#" data-bs-toggle="dropdown"><i className="bi bi-three-dots"></i></a>
          <ul className="dropdown-menu dropdown-menu-end dropdown-menu-arrow">
            <li className="dropdown-header text-start">
              <h6>Filter</h6>
            </li>
            <li><a className="dropdown-item" href="#" onClick={() => handleFilterChange('Today')}>Today</a></li>
            <li><a className="dropdown-item" href="#" onClick={() => handleFilterChange('This Month')}>This Month</a></li>
            <li><a className="dropdown-item" href="#" onClick={() => handleFilterChange('This Year')}>This Year</a></li>
          </ul>
        </div>
        <div className="card-body">
          <h5 className="card-title">Reports <span>/ {filter}</span></h5>
          <div id="lineChartOverall" style={{ width: '100%' }}></div>
        </div>
      </div>
    </div>
  );
};

export default LineChartOverall;


// import React, { useEffect, useState } from 'react';
// import axios from 'axios';
// import ApexCharts from 'apexcharts';
// import { API_URL } from '../constants/Url';
// import { useGlobalState } from '../constants/GlobalStateProvider'; // Adjust the import based on your context file location

// const LineChartOverall = () => {
//   const { getGlobal } = useGlobalState();
//   const globalState = getGlobal();
//   const [filter, setFilter] = useState('Today');
//   const [chartData, setChartData] = useState({});
//   const [machineIds, setMachineIds] = useState([]);

//   useEffect(() => {
//     if (globalState) {
//       fetchMachineInfo(globalState);
//     }
//   }, [globalState]);

//   useEffect(() => {
//     if (machineIds.length > 0) {
//       fetchData(machineIds);
//     }
//   }, [machineIds]);

//   const fetchMachineInfo = async (globalState) => {
//     try {
//       const response = await axios.get(`${API_URL}/machines/user/${globalState}`);
//       const data = response.data;

//       if (data.success) {
//         setMachineIds(data.machine_ids);
//       }
//     } catch (error) {
//       console.error('Error fetching user info:', error);
//     }
//   };

//   const fetchData = async (machineIds) => {
//     try {
//       const promises = machineIds.map(id => axios.get(`${API_URL}/feeds/fetch-last-feeds?id=${id}&results=1000`));
//       const responses = await Promise.all(promises);

//       const allFeeds = responses.map(response => response.data.feeds.reverse()); // Reverse the order of each feed array
//       const data = processChartData(allFeeds, machineIds);
//       console.log('Processed Data:', data); // Debugging
//       setChartData(data);
//     } catch (error) {
//       console.error('Error fetching data:', error);
//     }
//   };

//   useEffect(() => {
//     if (Object.keys(chartData).length > 0) {
//       const data = generateChartData(filter, chartData);
//       console.log('Chart Data:', data); // Debugging
//       const options = getChartOptions(data, filter);
//       const chart = new ApexCharts(document.querySelector("#lineChartOverall"), options);
//       chart.render();

//       return () => {
//         chart.destroy();
//       };
//     }
//   }, [filter, chartData]);

//   const processChartData = (allFeeds, machineIds) => {
//     const machines = {};

//     allFeeds.forEach((feeds, index) => {
//       const machineId = machineIds[index];
//       machines[machineId] = feeds.map(feed => ({
//         fuelpercentage: parseFloat(feed.field3),
//         timestamp: new Date(feed.created_at)
//       }));
//     });

//     return machines;
//   };

//   const generateChartData = (filter, machines) => {
//     const filteredData = {};

//     Object.keys(machines).forEach(machineId => {
//       const machineData = machines[machineId];

//       let aggregatedData = {};

//       switch (filter) {
//         case 'Today':
//           aggregatedData = machineData.reduce((acc, data) => {
//             const hour = data.timestamp.getHours();
//             if (!acc[hour]) {
//               acc[hour] = [];
//             }
//             acc[hour].push(data.fuelpercentage);
//             return acc;
//           }, {});
//           filteredData[machineId] = Object.keys(aggregatedData).map(hour => ({
//             x: new Date(new Date().setHours(hour, 0, 0, 0)),
//             y: aggregatedData[hour].reduce((sum, val) => sum + val, 0) / aggregatedData[hour].length
//           }));
//           break;
//         case 'This Month':
//           aggregatedData = machineData.reduce((acc, data) => {
//             const day = data.timestamp.getDate();
//             if (!acc[day]) {
//               acc[day] = [];
//             }
//             acc[day].push(data.fuelpercentage);
//             return acc;
//           }, {});
//           filteredData[machineId] = Object.keys(aggregatedData).map(day => ({
//             x: new Date(new Date().setDate(day)),
//             y: aggregatedData[day].reduce((sum, val) => sum + val, 0) / aggregatedData[day].length
//           }));
//           break;
//         case 'This Year':
//           aggregatedData = machineData.reduce((acc, data) => {
//             const month = data.timestamp.getMonth();
//             if (!acc[month]) {
//               acc[month] = [];
//             }
//             acc[month].push(data.fuelpercentage);
//             return acc;
//           }, {});
//           filteredData[machineId] = Object.keys(aggregatedData).map(month => ({
//             x: new Date(new Date().setMonth(month, 1)),
//             y: aggregatedData[month].reduce((sum, val) => sum + val, 0) / aggregatedData[month].length
//           }));
//           break;
//         default:
//           filteredData[machineId] = machineData.map(data => ({
//             x: data.timestamp,
//             y: data.fuelpercentage
//           }));
//       }
//     });

//     return filteredData;
//   };

//   const getChartOptions = (data, filter) => {
//     let xaxis;

//     switch (filter) {
//       case 'Today':
//         xaxis = {
//           type: 'datetime',
//           labels: {
//             datetimeFormatter: {
//               hour: 'HH:mm',
//             }
//           }
//         };
//         break;
//       case 'This Month':
//         xaxis = {
//           type: 'datetime',
//           labels: {
//             datetimeFormatter: {
//               day: 'dd MMM',
//             }
//           }
//         };
//         break;
//       case 'This Year':
//         xaxis = {
//           type: 'datetime',
//           labels: {
//             datetimeFormatter: {
//               month: 'MMM yyyy',
//             }
//           }
//         };
//         break;
//       default:
//         xaxis = {
//           type: 'datetime',
//         };
//     }

//     return {
//       series: Object.keys(data).map(machineId => ({
//         name: machineId,
//         data: data[machineId]
//       })),
//       chart: {
//         type: 'line',
//         toolbar: {
//           show: false
//         },
//       },
//       markers: {
//         size: 4
//       },
//       colors: ['#4154f1', '#2eca6a', '#ff771d'],
//       fill: {
//         type: "gradient",
//         gradient: {
//           shadeIntensity: 1,
//           opacityFrom: 0.3,
//           opacityTo: 0.4,
//           stops: [0, 90, 100]
//         }
//       },
//       dataLabels: {
//         enabled: false
//       },
//       stroke: {
//         curve: 'smooth',
//         width: 2
//       },
//       xaxis,
//       tooltip: {
//         x: {
//           format: 'dd MMM yyyy HH:mm'
//         },
//       },
//       responsive: [
//         {
//           breakpoint: 480,
//           options: {
//             chart: {
//               width: '100%'
//             }
//           }
//         }
//       ]
//     };
//   };

//   const handleFilterChange = (selectedFilter) => {
//     setFilter(selectedFilter);
//   };

//   return (
//     <div className="col-lg-8">
//       <div className="card">
//         <div className="filter">
//           <a className="icon" href="#" data-bs-toggle="dropdown"><i className="bi bi-three-dots"></i></a>
//           <ul className="dropdown-menu dropdown-menu-end dropdown-menu-arrow">
//             <li className="dropdown-header text-start">
//               <h6>Filter</h6>
//             </li>
//             <li><a className="dropdown-item" href="#" onClick={() => handleFilterChange('Today')}>Today</a></li>
//             <li><a className="dropdown-item" href="#" onClick={() => handleFilterChange('This Month')}>This Month</a></li>
//             <li><a className="dropdown-item" href="#" onClick={() => handleFilterChange('This Year')}>This Year</a></li>
//           </ul>
//         </div>
//         <div className="card-body">
//           <h5 className="card-title">Reports <span>/ {filter}</span></h5>
//           <div id="lineChartOverall" style={{ width: '100%' }}></div>
//         </div>
//       </div>
//       <div>
//         <h3>Machine IDs</h3>
//         <ul>
//           {machineIds.map(id => (
//             <li key={id}>{id}</li>
//           ))}
//         </ul>
//       </div>
//     </div>
//   );
// };

// export default LineChartOverall;
