// import React, { useEffect, useState } from 'react';
// import axios from 'axios';
// import { API_URL } from '../constants/Url';
// import { useGlobalState } from '../constants/GlobalStateProvider';

// const AllNotifications = () => {
//     const [notifications, setNotifications] = useState([]);
//     const { getGlobal } = useGlobalState();
//     const globalState = getGlobal();

//     useEffect(() => {
//         if (globalState) {
//           fetchNotifications(globalState);
//         }
//       }, [globalState]);

//         const fetchNotifications = async (globalState) => {
//             console.log("Fetching notifications for user_id:", globalState); // Log the user_id
//             try {
//                 const response = await axios.get(`${API_URL}/notification/getnotifications/${globalState}`);
//                 const datanotify = response.data;
//                 console.log('Notifications API response:', datanotify); // Log the API response
    
//                 if (datanotify.success) {
//                     const allNotifications = datanotify.notification.reduce((acc, item) => {
//                         return acc.concat(item.notify);
//                     }, []);
    
//                     console.log('All Notifications:', allNotifications); // Log the processed notifications
//                     setNotifications(allNotifications);
//                 } else {
//                     console.log('No notifications found or API failed');
//                     setNotifications([]);
//                 }
//             } catch (error) {
//                 console.error('Error fetching notifications:', error);
//             }
//         };
    
 
    

//     const handleAttend = async (notificationId, machineId) => {
//         try {
//             // Update the attended status to true for the selected notification
//             await axios.put(`http://localhost:5000/notification/notifications/${machineId}/attend`, { notificationId });
//             // Refresh the notifications list
//             setNotifications(prevNotifications =>
//                 prevNotifications.map(notify =>
//                     notify._id === notificationId ? { ...notify, attended: true } : notify
//                 )
//             );
//         } catch (error) {
//             console.error('Error updating notification status:', error);
//         }
//     };

//     return (
//         <div>
//             <h2>All Notifications</h2>
//             <table>
//                 <thead>
//                     <tr>
//                         <th>Type</th>
//                         <th>Time</th>
//                         <th>Machine ID</th>
//                         <th>Attended</th>
//                         <th>Action</th>
//                     </tr>
//                 </thead>
//                 <tbody>
//                     {notifications.length > 0 ? (
//                         notifications.map((notify) => (
//                             <tr key={notify._id}>
//                                 <td>{notify.notificationType}</td>
//                                 <td>{new Date(notify.time).toLocaleString()}</td>
//                                 <td>{notify.machine_id}</td>
//                                 <td>{notify.attended ? 'Yes' : 'No'}</td>
//                                 <td>{notify._id}</td>
//                                 <td>{notify.machine_id}</td>
//                                 <td>
//                                     {!notify.attended && (
//                                         <button onClick={() => handleAttend(notify._id, notify.machine_id)}>
//                                             Mark as Attended
//                                         </button>
//                                     )}
//                                 </td>
//                             </tr>
//                         ))
//                     ) : (
//                         <tr>
//                             <td colSpan="5">No notifications available</td>
//                         </tr>
//                     )}
//                 </tbody>
//             </table>
//         </div>
//     );
// };

// export default AllNotifications;

import React, { useEffect, useState } from 'react';
import axios from 'axios';
import { API_URL } from '../constants/Url';
import { useGlobalState } from '../constants/GlobalStateProvider';
import './AllNotifications.css'; // Import the CSS file

const AllNotifications = () => {
    const [notifications, setNotifications] = useState([]);
    const { getGlobal } = useGlobalState();
    const globalState = getGlobal();

    useEffect(() => {
        if (globalState) {
          fetchNotifications(globalState);
        }
      }, [globalState]);

    const fetchNotifications = async (globalState) => {
        console.log("Fetching notifications for user_id:", globalState);
        try {
            const response = await axios.get(`${API_URL}/notification/getnotifications/${globalState}`);
            const datanotify = response.data;
            console.log('Notifications API response:', datanotify);

            if (datanotify.success) {
                const allNotifications = datanotify.notification.reduce((acc, item) => {
                    return acc.concat(item.notify);
                }, []);
                console.log('All Notifications:', allNotifications);
                setNotifications(allNotifications);
            } else {
                console.log('No notifications found or API failed');
                setNotifications([]);
            }
        } catch (error) {
            console.error('Error fetching notifications:', error);
        }
    };

    const handleAttend = async (notificationId, machineId) => {
        try {
            await axios.put(`http://localhost:5000/notification/notifications/${machineId}/attend`, { notificationId });
            setNotifications(prevNotifications =>
                prevNotifications.map(notify =>
                    notify._id === notificationId ? { ...notify, attended: true } : notify
                )
            );
        } catch (error) {
            console.error('Error updating notification status:', error);
        }
    };

    return (
        <div className="container">
            <h2>All Notifications</h2>
            <table>
                <thead>
                    <tr>
                        <th>Type</th>
                        <th>Time</th>
                        <th>Machine ID</th>
                        <th>Attended</th>
                        <th>Action</th>
                    </tr>
                </thead>
                <tbody>
                    {notifications.length > 0 ? (
                        notifications.map((notify) => (
                            <tr key={notify._id}>
                                <td>{notify.notificationType}</td>
                                <td>{new Date(notify.time).toLocaleString()}</td>
                                <td>{notify.machine_id}</td>
                                <td>{notify.attended ? 'Yes' : 'No'}</td>
                                <td className="button-container">
                                    {notify.attended ? (
                                        <span className="attended-text">Attended</span>
                                    ) : (
                                        <button className="attend-button" onClick={() => handleAttend(notify._id, notify.machine_id)}>
                                            Mark as Attended
                                        </button>
                                    )}
                                </td>
                            </tr>
                        ))
                    ) : (
                        <tr>
                            <td colSpan="5">No notifications available</td>
                        </tr>
                    )}
                </tbody>
            </table>
        </div>
    );
};

export default AllNotifications;

