// import React from 'react';

// const LocationButton = () => {
//   const sectionStyle = {
//     padding: '60px 0',
//     backgroundColor: '#f8f9fa',
//   };

//   const containerStyle = {
//     maxWidth: '1140px',
//     margin: '0 auto',
//     padding: '0 15px',
//   };

//   const rowStyle = {
//     display: 'flex',
//     flexWrap: 'wrap',
//     alignItems: 'center',
//     justifyContent: 'space-between',
//   };

//   const textStyle = {
//     textAlign: 'center',
//   };

//   const textLgStartStyle = {
//     textAlign: 'left',
//   };

//   const ctaBtnContainerStyle = {
//     textAlign: 'center',
//   };

//   const ctaBtnStyle = {
//     display: 'inline-block',
//     padding: '10px 30px',
//     borderRadius: '50px',
//     transition: '0.5s',
//     marginTop: '10px',
//     color: '#fff',
//     backgroundColor: '#007bff',
//     textDecoration: 'none',
//     cursor: 'pointer',
//   };

//   return (
//     <section id="cta" style={sectionStyle}>
//       <div style={containerStyle}>
//         <div style={rowStyle}>
//           <div style={{ ...textStyle, ...textLgStartStyle }}>
//             <h3>Call To Action</h3>
//             <p>
//               Duis aute irure dolor in reprehenderit in voluptate velit esse
//               cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat
//               cupidatat non proident, sunt in culpa qui officia deserunt mollit
//               anim id est laborum.
//             </p>
//           </div>
//           <div style={ctaBtnContainerStyle}>
//             <a style={ctaBtnStyle} href="#">
//               Call To Action
//             </a>
//           </div>
//         </div>
//       </div>
//     </section>
//   );
// };

// export default LocationButton;

import React, { useState } from 'react';
import { Link, useNavigate } from 'react-router-dom';

const LocationButton = () => {
  const [showLocationComponent, setShowLocationComponent] = useState(false);
  const navigate = useNavigate(); 

  const sectionStyle = {
    padding: '60px 0',
    backgroundColor: '#f8f9fa',
    backgroundImage: 'url("assets/img/locationbg2.jpg")', // Replace with your desired image URL
    backgroundSize: 'cover',
    backgroundPosition: 'center',
  };

  const containerStyle = {
    maxWidth: '1140px',
    margin: '0 auto',
    padding: '0 15px',
  };

  const cardStyle = {
    backgroundColor: 'rgba(255, 255, 255, 0.9)',
    borderRadius: '10px',
    padding: '20px',
    boxShadow: '0 4px 8px rgba(0, 0, 0, 0.1)',
  };

  const rowStyle = {
    display: 'flex',
    flexWrap: 'wrap',
    alignItems: 'center',
    justifyContent: 'space-between',
  };

  const textStyle = {
    textAlign: 'center',
  };

  const textLgStartStyle = {
    textAlign: 'left',
  };

  const ctaBtnContainerStyle = {
    textAlign: 'center',
  };

  const ctaBtnStyle = {
    display: 'inline-block',
    padding: '10px 30px',
    borderRadius: '50px',
    transition: '0.5s',
    marginTop: '10px',
    color: '#fff',
    backgroundColor: '#007bff',
    textDecoration: 'none',
    cursor: 'pointer',
  };

//   const handleClick = () => {
//     setShowLocationComponent(true);
//   };

  const handleClickLocation = () => {
    navigate('/IndexMachineLocation')
  };

  return (
    <section id="cta" style={sectionStyle}>
      <div style={containerStyle}>
        <div style={cardStyle}>
          <div style={rowStyle}>
            <div style={{ ...textStyle, ...textLgStartStyle }}>
              <h3>Set Machine Location</h3>
              <p>
              Set machine locations effortlessly with our
              intuitive dashboard interface, ensuring precise
              management and efficiency.
              </p>
            </div>
            <div style={ctaBtnContainerStyle}>
              <button style={ctaBtnStyle} onClick={handleClickLocation}>
                Set Location
              </button>
            </div>
          </div>
        </div>
        {showLocationComponent && <LocationComponent />}
      </div>
    </section>
  );
};

const LocationComponent = () => {
  const componentStyle = {
    marginTop: '20px',
    padding: '20px',
    backgroundColor: '#fff',
    borderRadius: '10px',
    boxShadow: '0 4px 8px rgba(0, 0, 0, 0.1)',
  };

  return (
    <div style={componentStyle}>
      <h3>Set Your Location</h3>
      <p>Here you can set your location.</p>
      {/* Add your location setting functionality here */}
    </div>
  );
};

export default LocationButton;

