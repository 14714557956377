import React from 'react';

function GensetTable({ displayedFeeds }) {
  return (
    <table className="table table-borderless datatable" style={{ marginTop: '20px' }}>
      <thead>
        <tr>
          <th scope="col">Sr No.</th>
          <th scope="col">Last Update Time</th>
          <th scope="col">Fuel Level</th>
          <th scope="col">Fuel Percentage</th>
          <th scope="col">Battery Voltage</th>
          <th scope="col">Genset Status</th>
        </tr>
      </thead>
      <tbody>
        {displayedFeeds && displayedFeeds.length > 0 ? (
          displayedFeeds.map((feed, index) => {
            const fuelLevel = parseFloat(feed.fuelLevel) || 0;
            const batteryVoltage = parseFloat(feed.batteryVoltage).toFixed(2) || 'N/A';
            const gensetStatus = parseInt(feed.field5);
            const fuelPercentage = parseInt(feed.field3);
            return (
              <tr key={feed.created_at}>
                <td>{index + 1}</td>
                <td>{new Date(feed.created_at).toLocaleString()}</td>
                <td>{fuelLevel} liters</td>
                <td>{fuelPercentage} %</td>
                <td>{batteryVoltage} V</td>
                <td>{gensetStatus === 1 ? 'ON' : 'OFF'}</td>
                
              </tr>
            );
          })
        ) : (
          <tr>
            <td colSpan="5">No data available</td>
          </tr>
        )}
      </tbody>
    </table>
  );
}

export default GensetTable;
