



// import React, { useState, useEffect } from 'react';
// import { useGlobalState } from '../constants/GlobalStateProvider';
// import { useLocation } from 'react-router-dom';
// import axios from 'axios';
// import { API_URL } from '../constants/Url';
// import { Link } from 'react-router-dom';

// function DataCards() {
//   const [MachineInfo, setMachineInfo] = useState(() => {
//     const savedMachineInfo = localStorage.getItem('MachineInfo');
//     const cacheTimestamp = localStorage.getItem('MachineInfoTimestamp');
//     const isCacheValid = cacheTimestamp && (Date.now() - cacheTimestamp) < 180000; // Cache valid for 3 minutes

//     return savedMachineInfo && isCacheValid ? JSON.parse(savedMachineInfo) : '';
//   });

//   const location = useLocation();
//   const { getGlobal, setGlobal } = useGlobalState();
//   const globalState = getGlobal();

//   useEffect(() => {
//     if (globalState && !MachineInfo) {
//       fetchMachineInfo(globalState);
//     }
//   }, [location, globalState, MachineInfo]);

//   useEffect(() => {
//     const interval = setInterval(() => {
//       if (globalState) {
//         fetchMachineInfo(globalState, true);
//       }
//     }, 180000); // Refresh every 3 minutes

//     return () => clearInterval(interval);
//   }, [globalState]);

//   const fetchMachineInfo = async (globalState, forceRefresh = false) => {
//     try {
//       const cacheTimestamp = localStorage.getItem('MachineInfoTimestamp');
//       const isCacheValid = cacheTimestamp && (Date.now() - cacheTimestamp) < 180000; // Cache valid for 3 minutes

//       if (isCacheValid && !forceRefresh) return; // Skip fetching if cache is valid and not forced

//       const response = await axios.get(`${API_URL}/machines/user/${globalState}`);
//       const data = response.data;

//       if (data.success) {
//         setMachineInfo(data);
//         localStorage.setItem('MachineInfo', JSON.stringify(data)); // Cache the data in localStorage
//         localStorage.setItem('MachineInfoTimestamp', Date.now().toString()); // Update cache timestamp
//       }
//     } catch (error) {
//       console.error('Error fetching machine info:', error);
//     }
//   };
  
//   const cardStyle = {
//     backgroundImage: 'url("assets/img/datacard4.jpg")',
//     backgroundSize: 'cover',
//     backgroundPosition: 'center',
//   };
//   const circleStyle = {
//     backgroundImage: 'url("assets/img/datacard4.jpg")',
//     backgroundSize: 'cover',
//     backgroundPosition: 'center',
//   };
//   const cardStyle1 = {
//     backgroundImage: 'url("assets/img/datacard4.jpg")',
//     backgroundSize: 'cover',
//     backgroundPosition: 'center',
//   };
//   const cardStyle2 = {
//     backgroundImage: 'url("assets/img/datacard5.jpg")',
//     backgroundSize: 'cover',
//     backgroundPosition: 'center',
//   };
//   const cardStyle3 = {
//     backgroundImage: 'url("assets/img/datacard7.jpg")',
//     backgroundSize: 'cover',
//     backgroundPosition: 'center',
//   };

//   return (
//     <div className="col-lg-4">
//       <div className="row">
//         <div className="col-xxl-4 col-xl-12">
//           <div className="card info-card customers-card" style={cardStyle1}>
//             <div className="card-body">
//               <h5 className="card-title">
//                 Total Machines <span>| </span>
//               </h5>
//               <div className="d-flex align-items-center">
//                 <div className="card-icon rounded-circle d-flex align-items-center justify-content-center" >
//                   <div className="icon-container">
//                     <i className="bi bi-people" />
//                   </div>
//                 </div>
//                 <div className="ps-3">
//                   <h6>{MachineInfo.machine_count}</h6>
//                   <span className="text-muted small pt-2 ps-1">
//                     {MachineInfo.machine_type}
//                   </span>
//                 </div>
//               </div>
//             </div>
//           </div>
//         </div>

//         <div className="col-xxl-4 col-md-12">
//           <div className="card info-card sales-card" style={cardStyle2}>
//             <div className="filter">
//               <a className="icon" href="#" data-bs-toggle="dropdown">
//                 <i className="bi bi-three-dots" />
//               </a>
//               <ul className="dropdown-menu dropdown-menu-end dropdown-menu-arrow">
//                 <li className="dropdown-header text-start">
//                   <h6>Filter</h6>
//                 </li>
//                 <li>
//                   <a className="dropdown-item" href="#">
//                     Today
//                   </a>
//                 </li>
//                 <li>
//                   <a className="dropdown-item" href="#">
//                     This Month
//                   </a>
//                 </li>
//                 <li>
//                   <a className="dropdown-item" href="#">
//                     This Year
//                   </a>
//                 </li>
//               </ul>
//             </div>
//             <div className="card-body">
//               <Link to={"/IndexLocation"}>
//                 <h5 className="card-title">
//                   Location <span>| Zones</span>
//                 </h5>
//                 <div className="d-flex align-items-center">
//                   <div className="card-icon rounded-circle d-flex align-items-center justify-content-center">
//                     <div className="icon-container">
//                       <i className="bi bi-geo-alt" />
//                     </div>
//                   </div>
//                   <div className="ps-3">
//                     <h6>Locate</h6>
//                     <span className="text-success small pt-1 fw-bold">Area</span>{" "}
//                     <span className="text-muted small pt-2 ps-1">Machine</span>
//                   </div>
//                 </div>
//               </Link>
//             </div>
//           </div>
//         </div>

//         <div className="col-xxl-4 col-md-12">
//           <div className="card info-card revenue-card" style={cardStyle3}>
//             <div className="filter">
//               <a className="icon" href="#" data-bs-toggle="dropdown">
//                 <i className="bi bi-three-dots" />
//               </a>
//               <ul className="dropdown-menu dropdown-menu-end dropdown-menu-arrow">
//                 <li className="dropdown-header text-start">
//                   <h6>Filter</h6>
//                 </li>
//                 <li>
//                   <a className="dropdown-item" href="#">
//                     Today
//                   </a>
//                 </li>
//                 <li>
//                   <a className="dropdown-item" href="#">
//                     This Month
//                   </a>
//                 </li>
//                 <li>
//                   <a className="dropdown-item" href="#">
//                     This Year
//                   </a>
//                 </li>
//               </ul>
//             </div>
//             <div className="card-body">
//               <h5 className="card-title">
//                 Analytics <span>| This Month</span>
//               </h5>
//               <div className="d-flex align-items-center">
//                 <div className="card-icon rounded-circle d-flex align-items-center justify-content-center" >
//                   <div className="icon-container" >
//                   {/* <i className="bi bi-graph-up" /> */}
//                   <i className="bi bi-bar-chart" />


//                   </div>
//                 </div>
//                 <div className="ps-3">
//                   <h6> Analyse your data</h6>
//                   <span className="text-success small pt-1 fw-bold">8%</span>{" "}
//                   <span className="text-muted small pt-2 ps-1">increase</span>
//                 </div>
//               </div>
//             </div>
//           </div>
//         </div>

//         <div className="col-12">
//           <div className="card recent-sales overflow-auto" style={cardStyle}>
//             <div className="filter">
//               <a className="icon" href="#" data-bs-toggle="dropdown">
//                 <i className="bi bi-three-dots" />
//               </a>
//               <ul className="dropdown-menu dropdown-menu-end dropdown-menu-arrow">
//                 <li className="dropdown-header text-start">
//                   <h6>Filter</h6>
//                 </li>
//                 <li>
//                   <a className="dropdown-item" href="#">
//                     Today
//                   </a>
//                 </li>
//                 <li>
//                   <a className="dropdown-item" href="#">
//                     This Month
//                   </a>
//                 </li>
//                 <li>
//                   <a className="dropdown-item" href="#">
//                     This Year
//                   </a>
//                 </li>
//               </ul>
//             </div>
//           </div>
//         </div>
//       </div>
//     </div>
//   );
// }

// export default DataCards;

import React, { useState, useEffect } from 'react';
import { useGlobalState } from '../constants/GlobalStateProvider';
import { useLocation } from 'react-router-dom';
import axios from 'axios';
import { API_URL } from '../constants/Url';
import { Link } from 'react-router-dom';

function DataCards() {
  const [MachineInfo, setMachineInfo] = useState('');
  const location = useLocation();
  const { getGlobal, setGlobal } = useGlobalState();
  const globalState = getGlobal();

  useEffect(() => {
    if (globalState && !MachineInfo) {
      fetchMachineInfo(globalState);
    }
  }, [location, globalState, MachineInfo]);

  const fetchMachineInfo = async (globalState) => {
    try {
      const response = await axios.get(`${API_URL}/machines/user/${globalState}`);
      const data = response.data;

      if (data.success) {
        setMachineInfo(data);
      }
    } catch (error) {
      console.error('Error fetching machine info:', error);
    }
  };

  const cardStyle = {
    backgroundImage: 'url("assets/img/datacard4.jpg")',
    backgroundSize: 'cover',
    backgroundPosition: 'center',
  };
  const circleStyle = {
    backgroundImage: 'url("assets/img/datacard4.jpg")',
    backgroundSize: 'cover',
    backgroundPosition: 'center',
  };
  const cardStyle1 = {
    backgroundImage: 'url("assets/img/datacard4.jpg")',
    backgroundSize: 'cover',
    width: '90%',
  
   
    backgroundPosition: 'center',
  };
  const cardStyle2 = {
    backgroundImage: 'url("assets/img/datacard5.jpg")',
    backgroundSize: 'cover',
    width: '90%',

   
    backgroundPosition: 'center',
  };
  const cardStyle3 = {
    backgroundImage: 'url("assets/img/datacard7.jpg")',
    backgroundSize: 'cover',
    width: '90%',

   
    backgroundPosition: 'center',
  }

  return (
    <div className="col-lg-4">
      <div >
        <div className="col-xxl-4 col-xl-12">
          
          <div className="card info-card customers-card" style={cardStyle1}>
            <div className="card-body">
            <Link to={"/IndexMachines"}>
              <h5 className="card-title">
                Total Machines <span>| </span>
              </h5>
              <div className="d-flex align-items-center">
                <div className="card-icon rounded-circle d-flex align-items-center justify-content-center">
                  <div className="icon-container">
                    <i className="bi bi-people" />
                  </div>
                </div>
                <div className="ps-3">
                  <h6>{MachineInfo.machine_count}</h6>
                  <span className="text-muted small pt-2 ps-1">
                    {MachineInfo.machine_type}
                  </span>
                </div>
              </div>
              </Link>
            </div>
          </div>
        </div>

        <div className="col-xxl-4 col-md-12">
          <div className="card info-card customers-card" style={cardStyle2}>
            <div className="card-body">
              <Link to={"/IndexLocation"}>
                <h5 className="card-title">
                  Location <span>| Zones</span>
                </h5>
                <div className="d-flex align-items-center">
                  <div className="card-icon rounded-circle d-flex align-items-center justify-content-center">
                    <div className="icon-container">
                      <i className="bi bi-geo-alt" />
                    </div>
                  </div>
                  <div className="ps-3">
                    <h6>Locate</h6>
                    <span className="text-success small pt-1 fw-bold">Area</span>{" "}
                    <span className="text-muted small pt-2 ps-1">Machine</span>
                  </div>
                </div>
              </Link>
            </div>
          </div>
        </div>

        <div className="col-xxl-4 col-md-12">
          <div className="card info-card customers-card" style={cardStyle3}>
            <div className="card-body">
            <Link to={"/IndexAnalytics"}>
              <h5 className="card-title">
                Analytics <span>| This Month</span>
              </h5>
              <div className="d-flex align-items-center">
                <div className="card-icon rounded-circle d-flex align-items-center justify-content-center">
                  <div className="icon-container">
                    <i className="bi bi-bar-chart" />
                  </div>
                </div>
                <div className="ps-3">
                  <h6>Analytics</h6>
                </div>
              </div>
              </Link>
            </div>
          </div>
        </div>

        <div className="col-12">
          <div className="card recent-sales overflow-auto" style={cardStyle}>
            <div className="filter">
              <a className="icon" href="#" data-bs-toggle="dropdown">
                <i className="bi bi-three-dots" />
              </a>
              <ul className="dropdown-menu dropdown-menu-end dropdown-menu-arrow">
                <li className="dropdown-header text-start">
                  <h6>Filter</h6>
                </li>
                <li>
                  <a className="dropdown-item" href="#">
                    Today
                  </a>
                </li>
                <li>
                  <a className="dropdown-item" href="#">
                    This Month
                  </a>
                </li>
                <li>
                  <a className="dropdown-item" href="#">
                    This Year
                  </a>
                </li>
              </ul>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default DataCards;
