import React, { useEffect, useState } from 'react';
import axios from 'axios';
import { useGlobalState } from '../constants/GlobalStateProvider';
import { FaBatteryFull, FaBatteryHalf, FaBatteryQuarter, FaBatteryThreeQuarters, FaBatteryEmpty, FaMapMarkerAlt } from 'react-icons/fa';
import { API_URL } from '../constants/Url';

const LocationMachineTable = ({ onZoneOrAreaClick }) => {
  const [machinesData, setMachinesData] = useState([]);
  const [channelInfo, setChannelInfo] = useState([]);
  const [machineIds, setMachineIds] = useState([]);
  const [fuelPercentages, setFuelPercentages] = useState({});
  const [fuelCapacities, setFuelCapacities] = useState({});
  const [locationAttributes, setLocationAttributes] = useState({});
  const [searchTerm, setSearchTerm] = useState('');
  const [currentPage, setCurrentPage] = useState(1);
  const [rowsPerPage] = useState(5);

  const { getGlobal } = useGlobalState();
  const globalState = getGlobal();

  useEffect(() => {
    if (globalState) {
      fetchMachineInfo(globalState);
    }
  }, [globalState]);

  useEffect(() => {
    if (machineIds.length > 0) {
      fetchData(machineIds);
    }
  }, [machineIds]);

  const fetchMachineInfo = async (globalState) => {
    try {
      const response = await axios.get(`${API_URL}/machines/user/${globalState}`);
      const data = response.data;

      if (data.success) {
        setMachineIds(data.machine_ids);

        const capacities = data.fuel_capacities.reduce((acc, { machine_id, fuel_capacity }) => {
          acc[machine_id] = fuel_capacity;
          return acc;
        }, {});
        setFuelCapacities(capacities);

        const locations = data.location_attributes.reduce((acc, { machine_id, ...attributes }) => {
          acc[machine_id] = attributes;
          return acc;
        }, {});
        setLocationAttributes(locations);
      }
    } catch (error) {
      console.error('Error fetching user info:', error);
    }
  };

  const fetchData = async (machineIds) => {
    try {
      const apiPromises = machineIds.map((machineId) => {
        const apiKey = machineId === '2422147' ? 'E6V6BU2L78L7YYAZ' : 'YR2MHT97ZBXYIL4Y';
        return axios.get(`https://api.thingspeak.com/channels/${machineId}/feeds.json?api_key=${apiKey}&results=1`);
      });

      const results = await axios.all(apiPromises);
      const dataArray = results.map((result) => result.data);
      const channels = dataArray.map((data) => data.channel);
      const feeds = dataArray.map((data) => data.feeds[0] || {});

      setChannelInfo(channels);
      setMachinesData(feeds);
      calculateFuelPercentages(feeds, fuelCapacities);
    } catch (error) {
      console.error('Error fetching data:', error);
    }
  };

  const calculateFuelPercentages = (machinesData, fuelCapacities) => {
    const percentages = {};
    machinesData.forEach((machine) => {
      const machineId = machine.channel_id;
      const fuelCapacity = fuelCapacities[machineId];
      if (fuelCapacity !== null && machine.field1 !== undefined) {
        const fuelPercentage = (parseFloat(machine.field1) / fuelCapacity) * 100;
        percentages[machineId] = fuelPercentage.toFixed(2);
      } else {
        percentages[machineId] = 'NA';
      }
    });
    setFuelPercentages(percentages);
  };

  const renderTableData = () => {
    const indexOfLastRow = currentPage * rowsPerPage;
    const indexOfFirstRow = indexOfLastRow - rowsPerPage;

    const filteredMachines = machinesData.filter((machine, index) => {
      const { id, name, zone, area } = channelInfo[index] || {};
      const fullName = `${name}`.toLowerCase();
      return (
        searchTerm === '' ||
        fullName.includes(searchTerm.toLowerCase()) ||
        id?.toString().includes(searchTerm.toLowerCase()) ||
        zone?.toLowerCase().includes(searchTerm.toLowerCase()) ||
        area?.toLowerCase().includes(searchTerm.toLowerCase())
      );
    });

    const currentRows = filteredMachines.slice(indexOfFirstRow, indexOfLastRow);

    return currentRows.map((machine, index) => {
      const machineId = channelInfo[index]?.id;
      const fuelCapacity = fuelCapacities[machineId];
      const locationInfo = locationAttributes[machineId] || {};
      const fuelLevel = parseFloat(machine.field1);
      const fuelPercentage = fuelCapacity && fuelLevel > 0 && fuelLevel <= fuelCapacity
        ? (fuelLevel / fuelCapacity) * 100
        : null;

      let fuelIcon;
      let fuelColor;
      if (fuelPercentage === null) {
        fuelIcon = <FaBatteryEmpty />;
        fuelColor = 'gray';
      } else if (fuelPercentage > 75) {
        fuelIcon = <FaBatteryFull />;
        fuelColor = 'green';
      } else if (fuelPercentage > 50) {
        fuelIcon = <FaBatteryThreeQuarters />;
        fuelColor = 'limegreen';
      } else if (fuelPercentage > 25) {
        fuelIcon = <FaBatteryHalf />;
        fuelColor = 'orange';
      } else {
        fuelIcon = <FaBatteryQuarter />;
        fuelColor = 'red';
      }

      return (
        <tr key={index}>
          <td>{machineId || 'N/A'}</td>
          <td>{locationInfo.machine_name || 'N/A'}</td>
          <td 
            className="clickable"
            onClick={() => onZoneOrAreaClick('zone', locationInfo.zone_name)}
          >
            {locationInfo.zone_name || 'N/A'}
          </td>
          <td 
            className="clickable"
            onClick={() => onZoneOrAreaClick('area', locationInfo.location_name)}
          >
            {locationInfo.location_name || 'N/A'}
          </td>
          <td 
            className="clickable"
            onClick={() => onZoneOrAreaClick('location', `${locationInfo.latitude}, ${locationInfo.longitude}`)}
          >
            {locationInfo.latitude}, {locationInfo.longitude || 'N/A'}
            <FaMapMarkerAlt />
          </td>
          {/* <td>
            {fuelPercentage !== null
              ? <span className="battery-icon" style={{ color: fuelColor }}>
                {fuelIcon} <b><span className="battery-text">{fuelPercentage.toFixed(2)}%</span></b>
              </span>
              : 'NA'}
          </td> */}
          <td>
            <button className={`btn ${machine.field2 === "1" ? 'btn-success' : 'btn-danger'}`}>
              {machine.field2 === "1" ? "On" : "Off"}
            </button>
          </td>
        </tr>
      );
    });
  };

  const nextPage = () => setCurrentPage((prevPage) => prevPage + 1);
  const prevPage = () => setCurrentPage((prevPage) => prevPage - 1);

  const totalPages = Math.ceil(machinesData.length / rowsPerPage);

  return (
    <div className="col-12">
      <div className="card recent-sales overflow-auto">
        <div className="card-body">
          <h5 className="card-title">
            Machines Location | Total Machines: {machineIds.length}
          </h5>
          <div>
            <div className="search-bar">
              <input
                type="text"
                className="form-control"
                placeholder="Search by ID or Machine Name"
                value={searchTerm}
                onChange={(event) => setSearchTerm(event.target.value)}
              />
            </div>
            <table className="table table-hover">  
            <thead className="table-info">
                <tr>
                  <th scope="col">Machine ID</th>
                  <th scope="col">Machine Name</th>
                  <th scope="col">Zone</th>
                  <th scope="col">Area</th>
                  <th scope="col">Location</th>
                  {/* <th scope="col">Fuel Level</th> */}
                  <th scope="col">Status</th>
                </tr>
              </thead>
              <tbody>
                {renderTableData()}
              </tbody>
            </table>
            <div className="pagination">
              <div className="d-flex justify-content-between align-items-center">
                <div>
                  <button onClick={prevPage} disabled={currentPage === 1} className="btn btn-outline-secondary me-2">Previous</button>
                  <button onClick={nextPage} disabled={currentPage === totalPages} className="btn btn-outline-secondary">Next</button>
                </div>
              </div>
              <span className="page-no">Page {currentPage} of {totalPages}</span>
            </div>
          </div>
        </div>
      </div>
      <style jsx>{`
        .clickable {
          cursor: pointer;
                   transition: color 0.3s ease, transform 0.3s ease;
        }
        .clickable:hover {
          color: #f11241;
          transform: scale(1.03);
        }
        .battery-icon {
          display: flex;
          align-items: center;
        }
        .battery-text {
          margin-left: 5px;
        }
        .search-bar input {
          width: 100%;
          max-width: 300px;
          margin-bottom: 20px;
        }
        .pagination {
          display: flex;
          justify-content: space-between;
          align-items: center;
          margin-top: 20px;
        }
        .page-no {
          font-size: 14px;
          color: #666;
        }
        .table {
          width: 100%;
          border-collapse: collapse;
        }
        .table th,
        .table td {
          padding: 10px;
          text-align: left;
          vertical-align: middle;
        }
        .table th {
          font-weight: bold;
          border: 1px solid #dee2e6;
        }
       
        .table tbody tr:hover {
          background-color: #f1f1f1;
        }
      `}</style>
    </div>
  );
};

export default LocationMachineTable;

