import React from 'react';

function GensetInfo({ machineData }) {
  if (!machineData) return null;

  return (
    <div style={{ marginBottom: '20px', padding: '15px', backgroundColor: '#f9f9f9', borderRadius: '5px', border: '1px solid #ddd' }}>
      <h2>{machineData.channel.name}</h2>
      <p>Machine Id: {machineData.channel.id}</p>
      <p>Description: {machineData.channel.description}</p>
      <p>Location: {machineData.channel.latitude}, {machineData.channel.longitude}</p>
    </div>
  );
}

export default GensetInfo;
