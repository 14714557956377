// import React from 'react';
// import { Line, Pie, Bar } from 'react-chartjs-2';
// import 'chart.js/auto';

// function GensetCharts({ showLineChart, showPieChart, showBarChart, displayedFeeds, totalDuration, totalFuelConsumed, dailyOnTime }) {
//   return (
//     <>
//       {showLineChart && displayedFeeds.length > 0 && (
//         <Line
//           data={{
//             labels: displayedFeeds.map(feed => new Date(feed.created_at).toLocaleString()),
//             datasets: [
//               {
//                 label: 'Fuel Level (liters)',
//                 data: displayedFeeds.map(feed => parseFloat(feed.fuelLevel)),
//                 borderColor: 'rgba(75, 192, 192, 1)',
//                 backgroundColor: 'rgba(75, 192, 192, 0.2)',
//                 fill: true,
//                 yAxisID: 'y',
//               },
//               {
//                 label: 'Battery Voltage (V)',
//                 data: displayedFeeds.map(feed => parseFloat(feed.batteryVoltage)),
//                 borderColor: 'rgba(153, 102, 255, 1)',
//                 backgroundColor: 'rgba(153, 102, 255, 0.2)',
//                 fill: true,
//                 yAxisID: 'y1',
//               },
//             ],
//           }}
//           options={{
//             responsive: true,
//             plugins: {
//               legend: {
//                 position: 'top',
//               },
//               title: {
//                 display: true,
//                 text: 'Genset Fuel Level and Battery Voltage Over Time',
//               },
//             },
//             scales: {
//               y: {
//                 type: 'linear',
//                 display: true,
//                 position: 'left',
//                 title: {
//                   display: true,
//                   text: 'Fuel Level (liters)',
//                 },
//               },
//               y1: {
//                 type: 'linear',
//                 display: true,
//                 position: 'right',
//                 grid: {
//                   drawOnChartArea: false, // only want the grid lines for one axis to show up
//                 },
//                 title: {
//                   display: true,
//                   text: 'Battery Voltage (V)',
//                 },
//               },
//             },
//           }}
//         />
//       )}

//       {showPieChart && (
//         <Pie
//           data={{
//             labels: ['Total Duration (hours)', 'Total Fuel Consumed (liters)'],
//             datasets: [
//               {
//                 data: [totalDuration, totalFuelConsumed],
//                 backgroundColor: ['rgba(75, 192, 192, 0.2)', 'rgba(153, 102, 255, 0.2)'],
//                 borderColor: ['rgba(75, 192, 192, 1)', 'rgba(153, 102, 255, 1)'],
//                 borderWidth: 1,
//               },
//             ],
//           }}
//           options={{
//             responsive: true,
//             plugins: {
//               legend: {
//                 position: 'top',
//               },
//               title: {
//                 display: true,
//                 text: 'Genset Operation Summary',
//               },
//             },
//           }}
//         />
//       )}

//       {showBarChart && dailyOnTime.length > 0 && (
//         <Bar
//           data={{
//             labels: dailyOnTime.map(item => item.date),
//             datasets: [
//               {
//                 label: 'Genset On Time (hours)',
//                 data: dailyOnTime.map(item => item.onTime),
//                 backgroundColor: 'rgba(54, 162, 235, 0.2)',
//                 borderColor: 'rgba(54, 162, 235, 1)',
//                 borderWidth: 1,
//               },
//             ],
//           }}
//           options={{
//             responsive: true,
//             plugins: {
//               legend: {
//                 position: 'top',
//               },
//               title: {
//                 display: true,
//                 text: 'Genset On Time Per Day',
//               },
//             },
//             scales: {
//               y: {
//                 beginAtZero: true,
//                 title: {
//                   display: true,
//                   text: 'On Time (hours)',
//                 },
//               },
//             },
//           }}
//         />
//       )}
//     </>
//   );
// }

// export default GensetCharts;
import React from 'react';
import { Line, Pie, Bar } from 'react-chartjs-2';
import 'chart.js/auto';

function GensetCharts({ showLineChart, showPieChart, showBarChart, displayedFeeds, totalDuration, totalFuelConsumed, dailyOnTime, hourlyOnTime, selectedDay }) {
  return (
    <>
      {showLineChart && displayedFeeds.length > 0 && (
        <Line
          data={{
            labels: displayedFeeds.map(feed => new Date(feed.created_at).toLocaleString()),
            datasets: [
              {
                label: 'Fuel Level (liters)',
                data: displayedFeeds.map(feed => parseFloat(feed.fuelLevel)),
                borderColor: 'rgba(75, 192, 192, 1)',
                backgroundColor: 'rgba(75, 192, 192, 0.2)',
                fill: true,
                yAxisID: 'y',
              },
              {
                label: 'Battery Voltage (V)',
                data: displayedFeeds.map(feed => parseFloat(feed.batteryVoltage)),
                borderColor: 'rgba(153, 102, 255, 1)',
                backgroundColor: 'rgba(153, 102, 255, 0.2)',
                fill: true,
                yAxisID: 'y1',
              },
            ],
          }}
          options={{
            responsive: true,
            plugins: {
              legend: {
                position: 'top',
              },
              title: {
                display: true,
                text: 'Genset Fuel Level and Battery Voltage Over Time',
              },
            },
            scales: {
              y: {
                type: 'linear',
                display: true,
                position: 'left',
                title: {
                  display: true,
                  text: 'Fuel Level (liters)',
                },
              },
              y1: {
                type: 'linear',
                display: true,
                position: 'right',
                grid: {
                  drawOnChartArea: false, // only want the grid lines for one axis to show up
                },
                title: {
                  display: true,
                  text: 'Battery Voltage (V)',
                },
              },
            },
          }}
        />
      )}

      {showPieChart && (
        <Pie
          data={{
            labels: ['Total Duration (hours)', 'Total Fuel Consumed (liters)'],
            datasets: [
              {
                data: [totalDuration, totalFuelConsumed],
                backgroundColor: ['rgba(75, 192, 192, 0.2)', 'rgba(153, 102, 255, 0.2)'],
                borderColor: ['rgba(75, 192, 192, 1)', 'rgba(153, 102, 255, 1)'],
                borderWidth: 1,
              },
            ],
          }}
          options={{
            responsive: true,
            plugins: {
              legend: {
                position: 'top',
              },
              title: {
                display: true,
                text: 'Genset Operation Summary',
              },
            },
          }}
        />
      )}

      {showBarChart && selectedDay === 'all' && dailyOnTime.length > 0 && (
        <Bar
          data={{
            labels: dailyOnTime.map(item => item.date),
            datasets: [
              {
                label: 'Genset On Time (hours)',
                data: dailyOnTime.map(item => item.onTime),
                backgroundColor: 'rgba(54, 162, 235, 0.2)',
                borderColor: 'rgba(54, 162, 235, 1)',
                borderWidth: 1,
              },
            ],
          }}
          options={{
            responsive: true,
            plugins: {
              legend: {
                position: 'top',
              },
              title: {
                display: true,
                text: 'Genset On Time Per Day',
              },
            },
            scales: {
              y: {
                beginAtZero: true,
                title: {
                  display: true,
                  text: 'On Time (hours)',
                },
              },
            },
          }}
        />
      )}

      {showBarChart && selectedDay !== 'all' && hourlyOnTime.length > 0 && (
        <Bar
          data={{
            labels: hourlyOnTime.map(item => `${item.hour}:00`),
            datasets: [
              {
                label: 'Genset On Time (hours)',
                data: hourlyOnTime.map(item => item.onTime),
                backgroundColor: 'rgba(255, 206, 86, 0.2)',
                borderColor: 'rgba(255, 206, 86, 1)',
                borderWidth: 1,
              },
            ],
          }}
          options={{
            responsive: true,
            plugins: {
              legend: {
                position: 'top',
              },
              title: {
                display: true,
                text: `Genset On Time Per Hour on ${selectedDay}`,
              },
            },
            scales: {
              y: {
                beginAtZero: true,
                title: {
                  display: true,
                  text: 'On Time (hours)',
                },
              },
            },
          }}
        />
      )}
    </>
  );
}

export default GensetCharts;
